import { FileResultDto } from 'models/Util';
import { postRequest, sendRequest } from './gateway';

async function uploadFile(file: File): Promise<FileResultDto> {
  const fileData = new FormData();
  fileData.append('file', file);
  const fetchPromise = postRequest<FileResultDto>('/v1/file', fileData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  });

  return await sendRequest(fetchPromise);
}

function initialValue(name: string) {
  if (!name) {
    return name;
  }
  const parts = name.toUpperCase().trim().split(' ');

  if (parts.length > 1) {
    const first = parts[0];
    const last = parts.pop();
    return `${first[0]}${last[0]}`;
  } else if (parts.length) {
    const first = parts[0];
    return first[0];
  }
}

export { uploadFile, initialValue };
