import { TagDto } from 'models/post';
import { create } from 'zustand';

interface Action {
  fetchPostList: (selectedTag: TagDto , searchQueryString : string) => Promise<void>;
  setMyContentCurrentTab: (tab: string) => void;
}

interface State {
  myContentCurrentTab: string;
  selectedTag : TagDto;
  searchQueryString: string;
}

const getMyPostListStore = create<State & Action>(set => ({
  myContentCurrentTab: null,
  selectedTag : undefined,
  searchQueryString : undefined,
  fetchPostList: async (selectedTag, searchQueryString) => {
    set({selectedTag , searchQueryString});
  },
  setMyContentCurrentTab: (tab) => {
    set({ myContentCurrentTab: tab });
  },
}));

export { getMyPostListStore };
