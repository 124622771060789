import { useEffect, useLayoutEffect } from 'react';
import MatBackdrop from '@mui/material/Backdrop';
import { styled } from '@mui/material/styles';
import { Outlet, useLocation } from 'react-router-dom';
import { FlexColumn } from 'views/component/styled/flex';
import { login } from 'service/AccountService';
import { useSpinnerStore } from 'store/spinner';
import { useAccountStore } from 'store/account';
import { SpinnerIcon } from './Icons';

const Backdrop = styled(MatBackdrop)({
  zIndex: 9999
});

const SecuredLayout = () => {
  const { isAuthenticated } = useAccountStore();
  const { spinnerShown } = useSpinnerStore();
  const location = useLocation();

  useEffect(() => {
    login();
  }, []);

  useLayoutEffect(() => {
    document.documentElement.scrollTo({ top:0, left:0 });
  }, [location.pathname]);

  return (
    <>
      {!isAuthenticated && (
        <FlexColumn align='center' size='xl'>
          <iframe
            title='bff-silent-login'
            id='bff-silent-login'
            hidden
          ></iframe>
          <img
            className='logo'
            src='/logo.png'
            alt='logo'
            style={{ marginTop: '10%' }}
          />
          <h1>Authenticating to Aveon Services</h1>
        </FlexColumn>
      )}
      {isAuthenticated && (
        <div className='container'>
          <Outlet />
        </div>
      )}
      <Backdrop open={spinnerShown}>
        <SpinnerIcon />
      </Backdrop>
    </>
  );
};

export default SecuredLayout;
