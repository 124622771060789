import './TopNavigationBar.scss';
import { Form } from '../element';
import { PostListParams } from 'models/post';
import { getMyPostListStore } from 'store/getMyPostListStore';
import { FlexRow } from '../styled/flex';
import ProfileMenu from '../ProfileMenu';
import { useLocation, useNavigate } from 'react-router-dom';
import { Autocomplete, TextField, styled } from '@mui/material';
import { useRef, useState } from 'react';
import { listTags } from 'gateways/tag';
import TagsBar from '../element/TagsBar';

const StyledAutoComplete = styled(Autocomplete)({
  '.MuiOutlinedInput-root': {
    height: '37px',
    fontFamily: 'Poppins',
    fontSize: 14,
  },
});

function TopNavigationBar() {
  const { fetchPostList } = getMyPostListStore();
  const [tags, setTags] = useState<any[]>([]);
  const [type, setTypeDetails] = useState<any>(null);
  const inputRef = useRef(null);
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const filter = { query: '' || '' };
  const params: PostListParams = {
    limit: 25,
    page: 1,
  };

  const handleSubmit = async (values: typeof filter) => {
    params.query = values.query;
  };

  const handleChange = async (event, newValue) => {
    if (!newValue) return;
    if (event.key === 'Enter') {
      return;
    }
    const selectedTagObject = tags.find(tag => tag.name.toLowerCase() === newValue.toLowerCase());
    setTypeDetails('');
    if (selectedTagObject?.id) {
      fetchPostList(selectedTagObject , selectedTagObject.name);
      navigate(`/tag/${selectedTagObject.name}`);
    }
  };

  const handleInputChange = async newValue => {
    if (newValue && typeof newValue?.target?.value === 'string') {
      const value = await listTags(newValue?.target?.value?.trim(), null);
      setTags(value?.collection);
    }
  };


  async function selectedTopic(event, params) {
    if (event.key === 'Enter') {
      event.preventDefault();
      const query = params.inputProps.value;
      fetchPostList(null, query);
      navigate(`/search?q=${query}`);
      setTypeDetails('');
      inputRef.current.blur();
    }
  }

  return (
    <>
      <FlexRow size='xs' className='nav-bar'>
        <FlexRow
          size='xs'
          justify='space-between'
          align='center'
          className='nav-container'
        >
          <div>
            <a className='brand' href='/'>
              <img src='/logo.png' alt='logo' />
            </a>
          </div>
          <Form initialValues={{ query: '' }}  onSubmit={handleSubmit}>
            <StyledAutoComplete
              id='free-solo-demo'
              sx={{ width: 500 }}
              freeSolo
              options={tags.map(option => option.name)}
              value={type}
              groupBy={() => 'Topics'}
              onChange={handleChange}
              onInputChange={handleInputChange}
              renderInput={params => (
                <TextField
                  {...params}
                  inputRef={inputRef}
                  placeholder='Search'
                  onKeyDown={(e) => selectedTopic(e, params)}
                />
              )}
            />
          </Form>
          <FlexRow size='sm'>
            <div className='user'>
              <ProfileMenu />
            </div>
          </FlexRow>
        </FlexRow>
        {(pathname == '/' || pathname.split('/').includes('home')) && (<TagsBar path={pathname.split('/')[2]}/>)}
      </FlexRow>
      
    </>
  );
}

export default TopNavigationBar;
