import React, { useState, useEffect } from 'react';

const TimeElapsed = ({ postedTime }) => {
  const [timeElapsed, setTimeElapsed] = useState('');

  useEffect(() => {
    const calculateTimeElapsed = () => {
      const currentTime = new Date();
      const timeDifference = currentTime.getTime() - postedTime.getTime();

      const seconds = Math.floor(timeDifference / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);
      const weeks = Math.floor(days / 7);
      const months = Math.floor(days / 30);

      let timeElapsedString = '';

      if (months > 0) {
        timeElapsedString = `${months} months ago`;
      } else if (weeks > 0) {
        timeElapsedString = `${weeks} weeks ago`;
      } else if (days > 0) {
        timeElapsedString = `${days} days ago`;
      } else if (hours > 0) {
        timeElapsedString = `${hours} hours ago`;
      } else if (minutes > 0) {
        timeElapsedString = `${minutes} minutes ago`;
      } else {
        timeElapsedString = `${seconds} seconds ago`;
      }

      setTimeElapsed(timeElapsedString);
    };

    calculateTimeElapsed();

    // Update time every minute
    const interval = setInterval(calculateTimeElapsed, 60000);

    return () => clearInterval(interval);
  }, [postedTime]);

  return <span>{timeElapsed}</span>;
};

export default TimeElapsed;