export function SpinnerIcon() {
  return (
    <svg
      width='64'
      height='64'
      viewBox='0 0 64 64'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='spin'
    >
      <g clipPath='url(#4wnk2k4p8a)'>
        <path
          d='M62.096 30.616c-.016.005-.036.01-.052.016l-.084.029c.063.093.115.184.16.274-.007-.107-.016-.213-.024-.32zm-13.603 14.51c-1.283 1.599-2.869 3.084-4.783 4.405-4.942 7.222 3.42 9.122 3.42 9.122 3.349-2.083 6.552-5.164 8.732-7.748-2.67-2.62-2.425-2.402-7.369-5.778zm2.057.654c-.01.013-.014.063.001.077.037.037.076.07.116.103v-.005c-.04-.059-.08-.116-.117-.175zM27.282 60.032l-.558.444c.005.04.01.082.01.123l.571-.539c-.008-.008-.015-.02-.023-.028zm-1.606 1.281-.013.005-.335.264.352-.032a.71.71 0 0 0-.011-.073l-.008-.008c-.02-.022-.016-.037.002-.05l-.008-.059c0-.01.008-.027.02-.044l.001-.003z'
          fill='url(#q4o0likrhb)'
        />
        <path
          d='M33.399 1.077c-41.58.462-43.644 60.078-1.057 61.362 7.982-4.18 1.664-9.827 1.664-9.827-21.573 3.618-35.542-29.66-8.58-40.827 21.035-5.745 34.937 18.668 22.95 33.34.075.038.15.08.227.131 1.333.867 2.492 1.585 3.763 2.567.612.473 1.194.985 1.773 1.498.443.393.863.813 1.292 1.221.136.13.28.252.416.38C70.94 32.18 57.43 3.43 33.4 1.078zm9.054 50.761c-.01.03-.016.059-.024.087.035-.002.07-.005.108-.005l.022.001-.106-.083zm3.774 2.847.236.185-.083-.08-.153-.105zm4.557.392.125.09c.145.105.288.214.43.324l.135-.111c-.238-.102-.463-.202-.69-.303z'
          fill='url(#4qovne58kc)'
        />
        <path
          d='m20.693 14.264-.195.095c-.04.072-.09.15-.142.229l.567-.17a6.554 6.554 0 0 0-.23-.154zm38.12 29.261c-.302.476-.598.946-.926 1.452-.033.99-.363 2.081-.628 2.93.248.192.492.39.735.59.094-.148.185-.295.275-.444.114-1.183.21-2.37.407-3.544.047-.328.091-.656.136-.983zm-39.619 5.503c-.316.246-.66.504-.715.542-.067.045-.158.126-.228.086-.008-.003-.01-.008-.013-.014-.061.14-.12.282-.212.402-.373.487-.503.448-.972.717-.332.19-.641.418-.984.587-.934.46-1.585.596-2.598.889a.754.754 0 0 1-.07.019c-.39.223-.785.428-1.173.617-.13.12-.268.204-.423.2-.011.005-.025.01-.035.016-.07.109-.14.164-.21.098-.57.261-1.099.488-1.522.68 5.138 5.116 12.617 8.56 22.504 8.858 7.983-4.181 1.664-9.828 1.664-9.828-5.758.966-10.972-.699-15.013-3.869zm23.46 3.096c-.01.03-.016.059-.024.087.035-.002.07-.005.108-.005l.022.002-.106-.084zm3.773 2.847.237.185-.084-.08-.152-.105zm4.557.392.125.09c.146.105.288.214.431.324l.135-.111c-.238-.101-.463-.202-.69-.303z'
          fill='url(#hp4whzaq5d)'
        />
      </g>
      <defs>
        <linearGradient
          id='q4o0likrhb'
          x1='52.565'
          y1='48.104'
          x2='43.275'
          y2='56.724'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#C6D0F4' stopOpacity='.278' />
          <stop offset='.949' stopColor='#fff' stopOpacity='.38' />
          <stop offset='1' stopColor='#fff' stopOpacity='.969' />
        </linearGradient>
        <linearGradient
          id='4qovne58kc'
          x1='22.398'
          y1='57.882'
          x2='52.813'
          y2='55.514'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#3460DC' />
          <stop offset='1' stopColor='#C6D0F4' stopOpacity='.278' />
        </linearGradient>
        <linearGradient
          id='hp4whzaq5d'
          x1='38.634'
          y1='58.994'
          x2='43.16'
          y2='59.907'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#3460DC' />
          <stop offset='1' stopColor='#C6D0F4' stopOpacity='.278' />
        </linearGradient>
        <clipPath id='4wnk2k4p8a'>
          <path fill='#fff' d='M0 0h64v64H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}
