import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $generateNodesFromDOM } from '@lexical/html';
import { $getRoot, LexicalEditor } from 'lexical';
import { useEffect } from 'react';

export default function DefaultValuePlugin({ value }: { value?: string }) {
  const [editor] = useLexicalComposerContext();

  const updateHtml = (editor: LexicalEditor, value: string) => {
    const root = $getRoot();
    const parser = new DOMParser();
    const dom = parser.parseFromString(value, 'text/html');
    const nodes = $generateNodesFromDOM(editor, dom);

    root.clear();
    root.append(...nodes);
  };

  useEffect(() => {
    if (editor && value) {
      editor.update(() => {
        updateHtml(editor, value);
      });
    }
  }, [value]);

  return null;
}
