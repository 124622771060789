import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const StyledBox = styled(Box)({
  width: '100%',
  flexGrow: 1,
  marginTop: 16,
});

type SponsoredProps = {
  collection:any
}

function SwipeableTextMobileStepper(props:SponsoredProps) {
  return (
    <StyledBox>
      <Carousel
        infiniteLoop={true}
        autoPlay={true}
        showStatus={false}
        showArrows={false}
        showThumbs={false}
        showIndicators={false}
        interval={2000}
      >
        {
          props?.collection && 
          props?.collection.map((data, index) => (
            <div key={index}>
              <img src={data.cover_image_link} height={447} />
            </div>
          ))
        }
      </Carousel>
    </StyledBox>
  );
}

export default SwipeableTextMobileStepper;
