import { RouteObject } from 'react-router-dom';
import CreateLanding from 'views/pages/createLanding/CreateLanding';
import MyContent from 'views/pages/myContent/MyContent';
import PostEditor from 'views/pages/post/PostEditor';
import SecuredLayout from 'views/shared/SecuredLayout';
import PostEditorLayout from 'views/shared/PostEditorLayout';
import PostSettings from 'views/pages/post/PostSettings';
import PostHome from 'views/pages/post/PostHome';
import PostLayout from 'views/shared/PostLayout';
import Topics from 'views/pages/topics/topics';
import { resetEditor } from 'store/post';
import SearchTopics from 'views/pages/searchQuery/searchQuery';
import OverAllPost from 'views/pages/overAllPost/overAllPost';
import PostDetail from 'views/pages/post/PostDetailView';
import HomeTab from 'views/pages/post/HomeTab';

function postLoader() {
  resetEditor();
  return null;
}

const routes: RouteObject[] = [
  {
    path: '/',
    element: <SecuredLayout />,
    children: [
      {
        path: '/',
        element: <PostLayout />,
        children: [
          {
            path: '',
            element: <PostHome />
          },
          { path: '/:instituteName/:routePath', element: <PostDetail /> },
          { path: '/:routePath', element: <PostDetail /> },
          { path: '/create', element: <CreateLanding /> },
          { path: '/me/contents', element: <MyContent /> },
          { path: 'post/:postId/settings', element: <PostSettings /> },
          { path: 'event/:postId/settings', element: <PostSettings /> },
          { path: 'news/:postId/settings', element: <PostSettings /> },
          { path: 'advertisement/:postId/settings', element: <PostSettings /> },
          { path: '/tag/:topic', element: <Topics /> },
          { path : '/search',element: <SearchTopics />},
          { path : '/over-all-post/:type',element: <OverAllPost />},
          {path: '/home/:type',element:<HomeTab />}
        ]
      },
    ]
  },
  {
    path: '/',
    element: <SecuredLayout />,
    children: [
      {
        path: '/',
        element: <PostEditorLayout />,
        children: [
          { path: '/post/new', element: <PostEditor type='Post' />, loader: postLoader },
          { path: '/event/new', element: <PostEditor type='Event' />, loader: postLoader },
          { path: '/news/new', element: <PostEditor type='News' />, loader: postLoader },
          { path: '/advertisement/new', element: <PostEditor type='Advertisement' />, loader: postLoader },
          { path: '/post/:postId/edit', element: <PostEditor type='Post' />},
          { path: '/event/:postId/edit', element: <PostEditor type='Event' />},
          { path: '/news/:postId/edit', element: <PostEditor type='News' />},
          { path: '/advertisement/:postId/edit', element: <PostEditor type='Advertisement' />},
        ]
      },
    ]
  }
];

export default routes;
