import {
  FormikProvider,
  useFormik,
  FormikValues,
  FormikConfig,
  isFunction,
  FormikProps,
  isEmptyChildren,
} from 'formik';
import React from 'react';
  
  type ExtraProps = {
    className?: string
  }
  
export function Form<
    Values extends FormikValues = FormikValues
  >(props: FormikConfig<Values> & ExtraProps) {
  const formikBag = useFormik<Values>({ validateOnChange: false, ...props });
  const { component, children, render, innerRef, className } = props;
  const { handleReset, handleSubmit, handleChange } = formikBag;
  
  // This allows folks to pass a ref to <Formik />
  React.useImperativeHandle(innerRef, () => formikBag);
  
  return (
    <FormikProvider value={formikBag}>
      <form
        onSubmit={handleSubmit}
        onReset={handleReset}
        onChange={handleChange}
        action='#'
        autoComplete='off'
        className={className}
      >
        {component
          ? React.createElement(component, formikBag)
          : render
            ? render(formikBag)
            : children // children come last, always called
              ? isFunction(children)
                ? (children as (bag: FormikProps<Values>) => React.ReactNode)(
                  formikBag as FormikProps<Values>
                )
                : !isEmptyChildren(children)
                  ? React.Children.toArray(children)
                  : null
              : null}
      </form>
    </FormikProvider>
  );
}
  
Form.displayName = 'Form';
  