import { createTheme } from '@mui/material';


const fontFamily= 'Rubik, sans-serif';

let theme = createTheme({
  typography:{
    fontFamily
  }
});


theme = createTheme({
  palette: {
    primary: theme.palette.augmentColor({
      color: {
        main: '#1d4ed8'
      },
      name: 'primary'
    }),
    error: theme.palette.augmentColor({
      color: {
        main: '#ef4444'
      },
      name: 'error'
    }),
    secondary: theme.palette.augmentColor({
      color: {
        main: '#636B74'
      },
      name: 'secondary'
    }),
    warning: theme.palette.augmentColor({
      color: {
        main: '#e38323'
      },
      name: 'warning'
    })
  }
});

export {theme};
