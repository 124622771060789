import { Paged } from 'models/Pagination';
import { getRequest, sendRequest } from './gateway';
import { TagDto } from 'models/post';

async function listTags(query?: string, limit?: number) {
  const fetchPromise = getRequest<Paged<TagDto>>(
    `/v1/tag/list${query ? `?query=${query}` : ''}${
      query && limit ? `&limit=${limit}` : limit ? `?limit=${limit}` : ''
    }`,
    { skipSpinner: false }
  );
  return await sendRequest(fetchPromise);
}

export { listTags };
