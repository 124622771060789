import { addUserReaction, deleteUserReaction, getParticularPostDetails, listPosts, listUserProfiles } from 'gateways/post';
import { listTags } from 'gateways/tag';
import { UserProfileDto } from 'models/UserProfile';
import { PostDto, PostType, TagDto } from 'models/post';
import { create } from 'zustand';

export interface TagPostDto extends TagDto {
  posts?: PostDto[];
}

type State = {
  posts?: PostDto[];
  events?: PostDto[];
  news?: PostDto[];
  advertisements?: PostDto[];
  tagPosts?: TagPostDto[];
  userProfiles: Map<string, UserProfileDto>;
  isLoading?:boolean;
  post?: PostDto;
  relatedPosts?: PostDto[];
  count?:number;
}

type Action = {
  fetchHomeView: () => Promise<void>;
  fetchPost: (postId: string,isComments?:boolean) => Promise<void>;
  addReaction: (postId: string) => Promise<void>;
  removeReaction: (postId: string, reactionId: string) => Promise<void>;
};

const usePostViewStore = create<State & Action>()((set, get) => ({
  userProfiles: new Map(),
  fetchHomeView: async () => {
    set({isLoading:true});
    const fetchPosts = (type: PostType) => listPosts({ page: 1, limit: 10, type });
    const [posts, events, news, advertisements, tags] = await Promise.allSettled([
      fetchPosts('Post'),
      fetchPosts('Event'),
      fetchPosts('News'),
      fetchPosts('Advertisement'),
      listTags(undefined, 10)
    ]);

    const userIds: string[] = [];

    if (posts.status == 'fulfilled') {
      set({ posts: posts.value.collection?.slice(0,6) });
      userIds.push(...posts.value.collection.map(p => p.user_id));
    }

    if (events.status == 'fulfilled') {
      set({ events: events.value.collection?.slice(0,6)  });
      userIds.push(...events.value.collection.map(p => p.user_id));
    }

    if (news.status == 'fulfilled') {
      set({ news: news.value.collection?.slice(0,6)  });
      userIds.push(...news.value.collection.map(p => p.user_id));
    }

    if (advertisements.status == 'fulfilled') {
      set({ advertisements: advertisements.value.collection });
      userIds.push(...advertisements.value.collection.map(p => p.user_id));
    }

    if (tags.status == 'fulfilled') {
      const tagList = tags.value.collection.slice(0, 4);
      const tagPostResults = await Promise.allSettled(tagList.map(tag => listPosts({ page: 1, limit: 10, tagId: tag.id })));

      const tagPosts = tagList.map((tag, index) => {
        const result = tagPostResults[index];
        if (result.status === 'fulfilled') {
          userIds.push(...result.value.collection.map(p => p.user_id));
        }
        return { ...tag, posts: result.status === 'fulfilled' ? result.value.collection?.slice(0,6) : [] };
      });

      set({ tagPosts });
    }

    const { userProfiles } = get();

    const uniqueUserIds = Array.from(new Set(userIds));
    const newUserProfiles = new Map((await listUserProfiles(uniqueUserIds)).map(p => [p.user_id, p]));

    set({ userProfiles: new Map([...userProfiles, ...newUserProfiles]),isLoading:false });
  },
  fetchPost: async (postId: string,isComments?:boolean) => {
    if(!isComments){
      set({isLoading:true});
    }
    
    const post = await getParticularPostDetails(postId);
    set({ post,isLoading:false,count:post.comments_count });

    const { userProfiles } = get();
    if (!userProfiles.get(post.user_id)) {
      const newUserProfiles = new Map((await listUserProfiles([post.user_id])).map(p => [p.user_id, p]));
      set({ userProfiles: new Map([...userProfiles, ...newUserProfiles]) });
    }

    if (post.tags?.length) {
      const tagPosts = await Promise.allSettled(post.tags.map(t => listPosts({ limit: 10, page: 1, tagId: t.id })));
      const posts = tagPosts.flatMap(result => {
        if (result.status === 'fulfilled') {
          return result.value.collection;
        }
      });
      const relatedPosts = [...new Map(posts.map(p => [p.post_id, p])).values()]
        .filter(relatedPost => relatedPost.post_id !== postId);
      set({ relatedPosts });
    }
  },
  addReaction: async (postId: string) => {
    await addUserReaction(postId);
    const post = await getParticularPostDetails(postId);
    set({ post });
  },
  removeReaction: async (postId: string, reactionId: string) => {
    await deleteUserReaction(postId, reactionId);
    const post = await getParticularPostDetails(postId);
    set({ post });
  }
}));

export { usePostViewStore };
