
import { getMyPostDetails } from 'gateways/post';
import {  PostCollectDto, PostListParams } from 'models/post';
import { create } from 'zustand';

   
interface myContentCategory{
  myContentType : string,
  myContent : PostCollectDto[],
  hasNextPage : boolean,
  currentPage : number,
  nextPage : number,
  status : string[],
  fromDate : Date,
  toDate : Date,
}

type State = {
  Posts : myContentCategory,
  Events : myContentCategory,
  News : myContentCategory,
  Ads : myContentCategory,
  content : PostCollectDto[]
}

export type MyContentStatekeys = keyof Omit<State, 'content'>;

type Action = {
  fetchMyContentList : (contentType : MyContentStatekeys, params : PostListParams) => Promise<void>;
  existingMyContentList: (contentType : MyContentStatekeys, params : PostListParams) => Promise<void>;
  refetchMyContentList : (contentType : MyContentStatekeys, postId : string) => Promise<void>;
  resetMyContentList : () => Promise<void>;
}

const emptyMyContent  = {
  myContentType : null,
  myContent : [],
  hasNextPage : false,
  nextPage : 0,
  currentPage : null,
  status : [],
  fromDate : null,
  toDate : null
} as myContentCategory;


const useMyContentPostStore = create<State & Action>()((set, get) => ({
  Posts : emptyMyContent,
  Events : emptyMyContent,
  News : emptyMyContent,
  Ads : emptyMyContent,
  content : [],
  fetchMyContentList: async (category : MyContentStatekeys , params : PostListParams) => {
    category ??= 'Posts' as any;
    const {nextPage, status , hasNextPage , myContentType , fromDate , toDate} = get()[category];

    if(hasNextPage && myContentType === params?.type && status?.toString() === params?.statuses.toString() && fromDate == params.fromDate && toDate == params.toDate){
      return;
    }


    params.page ??= status?.toString() != params?.statuses.toString()?  1 : nextPage +1;


    const response = await getMyPostDetails(params);
     
    set(state => {
      const myContent = params.page === 1 ? response.collection : params.statuses.toString() !== state[category].status.toString() ? response.collection :  state[category].myContent.concat(...response.collection);
      const data = {
        myContentType : params.type,
        myContent,
        currentPage : response.metadata.current_page,
        hasNextPage : response.metadata.current_page === response.metadata.last_page,
        status : params.statuses,
        nextPage : params.page,
        fromDate : params.fromDate,
        toDate : params.toDate
      } as myContentCategory;

      const content = [...myContent];
      return {[category] : data, content};
    });
  },
  existingMyContentList :  async (category : MyContentStatekeys , params : PostListParams) => {
    const {myContent , status } = get()[category];

    if(!(myContent.length) || status.toString() !== params.statuses.toString()){
      await get().fetchMyContentList(category , params);
    }else{
      set(() => {
        const content = [...myContent];
        return { content};
      });
    }
  },
  refetchMyContentList : async(category : MyContentStatekeys , postId : string) => {
    const {currentPage , myContent , myContentType , hasNextPage , status , nextPage , fromDate , toDate} = get()[category];
    set(() => {
      const myContentList = myContent.filter((post) => post.post_id?.toLowerCase() !== postId.toLowerCase());
      const data = {
        myContentType : myContentType,
        myContent : myContentList,
        currentPage : currentPage,
        hasNextPage : hasNextPage,
        status : status,
        nextPage : nextPage,
        fromDate : fromDate, 
        toDate : toDate
      } as myContentCategory;

      const content = [...myContentList];
      return {[category] : data , content};
    });
  },
  resetMyContentList : async() => {
    set(() => {
      const data = {
        myContentType : null,
        myContent: [],
        currentPage : null,
        hasNextPage : false,
        status : null,
        nextPage : null,
        fromDate : null,
        toDate : null
      } as myContentCategory;
      return {
        Posts: data,
        Events: data,
        News: data,
        Ads: data,
        content: [],
      };
    });
  } 
}));

export {useMyContentPostStore};
