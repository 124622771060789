import { IconButton, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAccountStore } from 'store/account';
import { EditIcon, GroupIcon, LogOutIcon, PlusIcon, SideUserIcon } from './element/Icons';
import { initialValue } from 'gateways/util';
import { capitalizeFirstLetter } from 'helper';
import { styled } from '@mui/material/styles';

const MenuIcon = styled('div')({
  marginRight: 8,
  height: 18,
  width: 18,
  marginTop: -2,
});

const menuProps = {
  elevation: 0,
  sx: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
};

export const Avatar = styled('div')({
  width: 36,
  height: 36,
  backgroundColor: '#0f172a',
  color: '#fff',
  fontSize: 14,
  fontWeight: 600,
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
});


const ProfileMenu = () => {
  const { user } = useAccountStore();
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleProfile = () => {
    handleClose();
    window.location.replace(user.authority);
  };

  const handleMyContent = () => {
    handleClose();
    navigate('/me/contents');
  };

  const handleCreate = () => {
    handleClose();
    navigate('/create');
  };

  const handleLogout = () => {
    handleClose();
    window.location.replace(user.logoutUrl);
  };

  return (
    <>
      <IconButton
        size='small'
        disableRipple
        onClick={handleClick}
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
      >
        <Avatar>{initialValue(user?.name)}</Avatar>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClick={handleClose}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        PaperProps={menuProps}
      >
        <MenuItem onClick={handleProfile}>
          <MenuIcon>
            <SideUserIcon />
          </MenuIcon>
          {capitalizeFirstLetter(user?.name)}
        </MenuItem>
        <MenuItem onClick={handleCreate}>
          <MenuIcon>
            <PlusIcon />
          </MenuIcon>
          Create
        </MenuItem>
        <MenuItem onClick={handleMyContent}>
          <MenuIcon>
            <EditIcon />
          </MenuIcon>
          My Content
        </MenuItem>
        <MenuItem disabled>
          <MenuIcon>
            <GroupIcon />
          </MenuIcon>
          Subscription
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <MenuIcon>
            <LogOutIcon />
          </MenuIcon>
          Signout
        </MenuItem>
      </Menu>
    </>
  );
};

export default ProfileMenu;
