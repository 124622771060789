import { Chip, styled } from '@mui/material';
import TimeElapsed from 'utils/time-utils';
import {
  StyledDivider,
  StyledIconContent,
  StyledText,
} from 'views/component/element/CardView';
import { FlexColumn, FlexRow } from 'views/component/styled/flex';
import { ImageContainer } from 'views/dialogs/publish/PublishDialog';
import { useNavigate } from 'react-router-dom';
import { Avatar } from 'views/component/ProfileMenu';
import { initialValue } from 'gateways/util';
import { getMyPostListStore } from 'store/getMyPostListStore';
import { useEffect, useState } from 'react';
import { listTags } from 'gateways/tag';
import { getMyPostDetailsStore, listUserProfiles } from 'gateways/post';
import { PostListParams, TagDto } from 'models/post';
import { UserProfileDto } from 'models/UserProfile';
import { TopicView } from 'views/component/Loader/Loading';
import { structureViewPostUrl } from 'helper';

const StyledRow = styled(FlexRow)({
  flexDirection: 'column',
});

export const TopContent = styled(FlexRow)({
  columnGap: 24,
});

const StyledHeaderRow = styled('h2')({
  fontFamily: 'Poppins',
  fontSize: 36,
  margin: '20px 0',
  height: 40,
  textTransform: 'capitalize',
});

export const StyledTitleRow = styled('h4')({
  fontFamily: 'Poppins',
  fontSize: 20,
  margin: '12px 0 6px',
  textTransform: 'capitalize',
  wordWrap: 'break-word',
  width: 288,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

const StyledTopics = styled(FlexRow)({
  fontFamily: 'Poppins',
  fontSize: 16,
  color: '#6B6B6B',
});

const StyledMoreData = styled(FlexRow)({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, auto)',
  margin: '20px auto 0',
  maxWidth: '1120px',
  rowGap: 24,
});

export const StyledColumns = styled(FlexColumn)({
  cursor: 'pointer',
});


export const StyledNoRecordContent = styled(FlexColumn)({
  color: '#6B6B6B',
  fontSize: 14,
});

const StyledChip = styled(Chip)({
  textTransform: 'capitalize',
  cursor: 'pointer',
});

const StyledOverallTopic = styled(FlexRow)({
  flexDirection: 'column',
  padding: '20px 0',
  maxWidth: 1120,
  margin: '0 auto',
});

const Topics = () => {
  const { selectedTag } = getMyPostListStore();
  const [topicsPost, setTopicsPost] = useState([]);
  const [tags, setTags] = useState([]);
  const [topic, setTopic] = useState('');
  const [paginationLastPageSize , setLastPageSize] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [userProfileList , setUserProfileList] = useState<UserProfileDto[]>([]);
  const [selectedTagDetail , setSelectedTag] = useState<TagDto>(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);



  useEffect(() => {
    getPostListBasedOnSelectedTag();
  },[selectedTag , selectedTagDetail]);

  const getPostListBasedOnSelectedTag = async () => {
    setIsLoading(true);
    const params : PostListParams = {
      page,
      limit:25,
      tagId : selectedTagDetail?.id || selectedTag.id,
      query:   null
    };

    const postData = await getMyPostDetailsStore(params);
    const userIds = postData.collection.map((user) => user.user_id);
    const uniqueUserIdList = [...new Set(userIds)];
    getUserDetailByUserId(uniqueUserIdList);
    setTopicsPost(previousData => [...previousData , ...postData.collection]);
    setLastPageSize(postData.metadata.last_page);
    setTopic(selectedTagDetail?.name);
    setIsLoading(false);
  };

  useEffect(() => {
    getTagList();
    setTopicsPost([]);
  },[selectedTag]);

  const getTagList = async () => {
    const tags = await listTags();
    if (tags.collection?.some(re => re.name == selectedTag?.name)) {
      setTags(tags.collection);
      const userIds = topicsPost.map((user) => user.user_id);
      const uniqueUserIdList = [...new Set(userIds)];
      getUserDetailByUserId(uniqueUserIdList as any);
      const index = tags.collection.findIndex(res=>res.name ==selectedTag?.name);
      setSelectedIndex(index);
    } else {
      tags.collection.unshift({
        name: selectedTag?.name || topic,
        id: selectedTag?.id || 0,
        post_count: selectedTag?.post_count || 0,
      });
      setTags(tags.collection);
    }
  };

  function Author({ userId,institution }: { userId: string,institution?:string }) {
    const user = userProfileList.find((user) => user.user_id === userId.toLowerCase());
    return (
      <><Avatar sx={{ height: 20, width: 20 }}>
        <StyledText
          sx={{
            fontSize: 8,
            textAlign: 'center',
            color: '#ffffff',
            marginTop: '1px !important',
          }}
        >
          {initialValue(user?.name)}
        </StyledText>
      </Avatar><StyledText sx={{
        fontSize: 12,
      }}>{user?.name ? user?.name : ''}</StyledText>
      {institution && (<StyledText sx={{
        fontSize: 12,
        marginLeft:'-4px',
        textOverflow:'ellipsis',
        width:100
      }}>{ `in ${institution}`}</StyledText>)}</>
    );
  }

  const  getUserDetailByUserId = async (userIds : string[]) => {
    const userProfile = await listUserProfiles(userIds);
    userProfile?.forEach((x) => x.user_id?.toLowerCase());
    setUserProfileList( previousUserList => [...previousUserList, ...userProfile]);
  };

  //infinity scroll
  window.onscroll = function () {
    const tolerance = 1;
    const scrollPosition = window.innerHeight + document.documentElement.scrollTop;
    const pageHeight = document.documentElement.offsetHeight;

    if (Math.abs(scrollPosition - pageHeight) <= tolerance) {
      scrollToEnd();
    }
  };

  const scrollToEnd = () => {
    if(paginationLastPageSize <= page || isLoading){
      return;
    }
    
    setPage(page + 1);
    getPostListBasedOnSelectedTag();
  };


  return (
    <>
      <StyledOverallTopic size='xs'>
        <FlexRow size='sm'>
          {tags.map((tag, index) => (
            <div key={tag.id}>
              <StyledChip
                label={tag.name}
                onClick={() => {
                  setTopicsPost([]);
                  setPage(1);
                  setSelectedTag(tag);
                  setSelectedIndex(index);
                }}
                sx={{
                  border:
                    selectedIndex == index
                      ? '1px solid black'
                      : '1px solid #f2e9e98c',
                }}
              />
            </div>
          ))}
        </FlexRow>
        <StyledRow
          size='xs'
          style={{ height: !topicsPost?.length ? '88vh' : '' }}
        >
          <StyledHeaderRow>
            {topic ? topic : selectedTag?.name}
          </StyledHeaderRow>
          <StyledTopics size='xs'>
            Topic - {topicsPost?.length} Stories
          </StyledTopics>
          <StyledDivider />
          {topicsPost?.length > 0 ? (
            <>
              <TopContent size='xs'>
                {topicsPost.slice(0, 2)?.map(element => (
                  <div key={element.post_id}>
                    <FlexRow size='md'>
                      <StyledColumns
                        size='xs'
                        onClick={() =>
                          navigate(structureViewPostUrl(element?.route_path, element?.institute_name))
                        }
                      >
                        <img
                          src={element.cover_image_link}
                          height={260}
                          style={{ maxWidth: 500 }}
                        />
                        <StyledTitleRow>{element?.title}</StyledTitleRow>
                        <StyledTitleRow
                          sx={{
                            marginTop: '0px !important',
                            color: '#6B6B6B',
                            fontSize: 16,
                            fontWeight: 400,
                            height: 24,
                          }}
                        >
                          {element?.subtitle}
                        </StyledTitleRow>
                        <StyledIconContent size='xs'>
                          <Author userId={element.user_id} institution={element?.institute_name}/>
                        </StyledIconContent>
                        <StyledIconContent
                          size='xs'
                          sx={{
                            color: '#6d6d6d',
                            fontWeight: 'unset',
                            marginTop: 0,
                          }}
                        >
                          <TimeElapsed
                            postedTime={
                              element.updated_date_time
                                ? new Date(element.updated_date_time)
                                : new Date(element.created_date_time)
                            }
                          />
                        </StyledIconContent>
                      </StyledColumns>
                    </FlexRow>
                  </div>
                ))}
              </TopContent>
              <StyledMoreData size='md'>
                {topicsPost?.slice(2, topicsPost?.length).map(data => (
                  <div key={data.post_id}>
                    <FlexRow size='md'>
                      <StyledColumns
                        size='xs'
                        onClick={() =>
                          navigate(structureViewPostUrl(data?.route_path, data?.institute_name))
                        }
                      >
                        <ImageContainer
                          sx={{
                            backgroundImage: `url(${data.cover_image_link})`,
                          }}
                        />
                        <StyledTitleRow>{data?.title}</StyledTitleRow>
                        <StyledTitleRow
                          sx={{
                            marginTop: '0px !important',
                            color: '#6B6B6B',
                            fontSize: 16,
                            fontWeight: 400,
                            height: 24,
                          }}
                        >
                          {data?.subtitle}
                        </StyledTitleRow>
                        <StyledIconContent size='xs'>
                          <Author userId={data.user_id} institution={data.institute_name}/>
                        </StyledIconContent>
                        <StyledIconContent
                          size='xs'
                          sx={{
                            color: '#6d6d6d',
                            fontWeight: 'unset',
                            marginTop: 0,
                          }}
                        >
                          <TimeElapsed
                            postedTime={
                              data.updated_date_time
                                ? new Date(data.updated_date_time)
                                : new Date(data.created_date_time)
                            }
                          />
                        </StyledIconContent>
                      </StyledColumns>
                    </FlexRow>
                  </div>
                ))}
              </StyledMoreData>
            </>
          ) : (
            <>{isLoading == true && (<TopicView />)}<FlexColumn size='xs'>
              <StyledNoRecordContent size='xs'>
                  Make sure all words are spelled correctly.
              </StyledNoRecordContent>
              <StyledNoRecordContent size='xs'>
                  Try different keywords.
              </StyledNoRecordContent>
              <StyledNoRecordContent size='xs'>
                  Try more general keywords.
              </StyledNoRecordContent>
            </FlexColumn></>
          )}
        </StyledRow>
      </StyledOverallTopic>
    </>
  );
};

export default Topics;
