import { RouterProvider } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { theme } from './theme';
import { createBrowserRouter } from 'react-router-dom';
import routes from './routes';
import ReactGA from 'react-ga';

const browserRouter = createBrowserRouter(routes);

const App: React.FC = () => {
  ReactGA.initialize('G-P9Z92VRP0P');
  return (
    <>
      <ThemeProvider theme={theme}>
        <RouterProvider router={browserRouter} />
      </ThemeProvider>
    </>
  );
};

export default App;
