export interface PostDto {
  post_id: string;
  user_id: string;
  type_id: string;
  venue: string;
  start_date_time: string;
  end_date_time: string;
  user_name: string;
  title: string;
  subtitle: string;
  content?: string;
  cover_image_link: string;
  status: string;
  published_date_time?: Date;
  archived_date_time?: Date;
  created_date_time: Date;
  updated_date_time?: Date;
  is_seo_synthesized: boolean;
  tags?: TagDto[];
  comments?: CommentDto[];
  reactions?: ReactionDto;
  comments_count?: number;
  is_title_synthesized: boolean;
  is_allow_comments: boolean;
  is_allow_reactions: boolean;
  seo_title: string;
  seo_description: string;
  social_title: string;
  is_notify_facebook: boolean;
  is_notify_twitter: boolean;
  region?: string;
  lifetime?: string;
  scheduled_on?: string;
  type: PostType;
  route_path?: string;
  institute_name?: string
}

export class TagDto {
  id: number;
  name: string;
  post_count: number;
}

export interface CommentDto {
  comment_id: string;
  user_id: string;
  post_id: string;
  content: string;
  created_date_time: Date;
  updated_date_time?: Date;
  user_name: string;
}

export interface Comment {
  content: string;
}

export interface ReactionDto {
  metadata: ReactionMetadataDto[];
  collection: {
    reaction_id: string;
    user_id: string;
    type: ReactionType;
    created_date_time: string;
  }[];
}

export interface ReactionMetadataDto {
  type: ReactionType;
  count: number
}

export type ReactionType = 'Like'

export interface MetaDataDto {
  total: number;
  limit: number;
  current_page: number;
  from: number;
  to: number;
  last_page: number;
}

export interface PostCollectDto {
  created_date_time: Date;
  updated_date_time: Date;
  post_id: string;
  type_id: number;
  user_id: string;
  title: string;
  subtitle: string;
  cover_image_link: string;
  published_date_time: Date;
  archived_date_time: Date;
  content: string;
  status: string;
  start_date_time?: Date;
  end_date_time?: Date;
  venue?: string;
  type : string;
  reactions: ReactionDto;
  comments_count: number;
  scheduled_on: string;
  tags: TagDto[];
  route_path : string;
  institute_name : string;
  seo_title?: string;
  seo_description ?: string;
}

export interface MyPostDetailsDto {
  metadata: MetaDataDto;
  collection: PostCollectDto[];
}

export interface PostListParams {
  page?: number;
  limit?: number;
  query?: string;
  type?: PostType;
  statuses?: string[];
  tagId?: number;
  fromDate?: Date;
  toDate?: Date;
}

export interface PostCreateOptions {
  title: string;
  subtitle?: string;
  type?: PostType;
  cover_image_link?: string;
  tags: TagDto[];
  content: string;
  lifetime?: string;
  scheduled_on?: string;
  is_title_synthesized: boolean;
  is_allow_comments: boolean;
  is_allow_reactions: boolean;
  seo_title: string;
  seo_description: string;
  social_title: string;
  is_notify_facebook: boolean;
  is_notify_twitter: boolean;
  region?: string;
  is_seo_synthesized: boolean;
  start_date_time?: string;
  end_date_time?: string;
  venue?: string
}

export interface MyPostCommentsDetailsDto {
  metadata: MetaDataDto;
  collection: CommentDto[];
}

export interface PostDetailsStore {
  data: MyPostDetailsDto
}

export interface PostActionDto {
  scheduled_on: string;
  lifetime: string;
  start_date_time?: string;
  end_date_time?: string;
  venue?: string;
  title?: string;
  subtitle?: string;
  cover_image_link?: string;
  tags?: TagDto[];
  is_title_synthesized?: boolean;
}

export enum PublishType {
  PublishNow = 'PublishNow',
  SchedulePublish = 'SchedulePublish',
  ChangeValue = 'Custom Date',
  Scheduled = 'Scheduled',
  Draft = 'Draft',
  Published = 'Published'
}

export type Durations = {
  [key: string]: number;
};

export type PostType = 'Post' | 'Event' | 'News' | 'Advertisement';

export enum MappingPostType {
  Posts = 'Post',
  Events = 'Event',
  News = 'News',
  Ads = 'Advertisement'
}

export enum PostListType {
  Post = '1',
  Event = '2'
}

export enum PostStatus {
  Draft = 'Draft',
  Published = 'Published',
  Scheduled = 'Scheduled',
  Archived = 'Archived',
  Deleted = 'Deleted',
  In_Review = 'In Review'
}

export enum PostTabs {
  Posts = 'Posts',
  Events = 'Events',
  News = 'News',
  Ads = 'Ads'
}

export interface PostActionsProps extends PostDto {
  enablePostAction: boolean
}
