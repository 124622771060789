import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FullNameAvatar } from './PostSettings';
import { initialValue } from 'gateways/util';
import { FlexColumn, FlexRow } from 'views/component/styled/flex';
import {
  LikeFilledIcon,
  MessageIcon,
  ThumbsUpIcon,
} from 'views/component/element/Icons';
import { PostActionsProps, PostDto } from 'models/post';
import { useAccountStore } from 'store/account';
import moment from 'moment';
import { Avatar, Drawer, Divider } from '@mui/material';
import { usePostViewStore } from 'store/post-view';
import { structureViewPostUrl } from 'helper';
import { DetailView } from 'views/component/Loader/Loading';
import ShareMenu from 'views/component/element/ShareMenu';
import CommentsSection from 'hooks/infinityScrollComments';

const format = (date: string | Date) => moment(date).format('MMMM DD, YYYY');

const Container = styled('div')({
  height: '100%',
  maxWidth: '1120px',
  width: '100%',
  margin: '40px auto 50px',
});

const EventData = styled('div')({
  fontWeight: 500,
  fontSize: '14px',
  paddingTop: '15px',
});

const PublishDate = styled('div')({
  fontWeight: 500,
  fontSize: '14px',
  color: '#6B6B6B',
});

const RelatedContainer = styled(FlexColumn)({
  backgroundColor: '#F9F9F9',
  height: 'auto',
  width: '100%',
  padding: '20px 0',
});

const ContentWrapper = styled(FlexRow)({
  width: '1120px',
  flexWrap: 'wrap',
  background: 'unset',
  rowGap: '45px',
});

const PostCard = styled('div')({
  width: 'calc(40% - 10px)',
  borderRadius: '8px',
  overflow: 'hidden',
  cursor: 'pointer',
});

const PostImage = styled('img')({
  width: '100%',
  height: '200px',
  objectFit: 'cover',
});

const PostContent = styled('div')({
  paddingTop: '10px',
});

const PostTitle = styled('h3')({
  fontSize: '18px',
  margin: '0 0 10px',
});

const PostSubtitle = styled('p')({
  fontSize: '14px',
  color: '#666',
});

const PostAuthor = styled(FlexRow)({
  margin: '10px 0',
  background: 'unset',
});

const AuthorAvatar = styled(Avatar)({
  width: '25px',
  height: '25px',
  backgroundColor: '#242424',
  fontSize: '10px',
  fontFamily: 'Rubik',
  marginRight: '6px',
});

const AuthorName = styled('div')({
  fontSize: '14px',
  fontWeight: '400',
  color: '#242424',
  textTransform:'capitalize'
});

const StyledDivider = styled(Divider)({
  marginTop: '32px',
  marginBottom: '32px',
});

function EventSchedule(props: PostDto) {
  const isSameDay = moment(props.start_date_time).isSame(props.end_date_time, 'day');
  const duration = isSameDay ? format(props.start_date_time) : `${format(props.start_date_time)} - ${format(props.end_date_time)}`;

  return (
    <>
      {props.type === 'Event' && (
        <EventData>{duration} | {props.venue}</EventData>
      )}
    </>
  );
}

function PostContentView(props: PostDto) {
  const { userProfiles } = usePostViewStore();
  const post = parseContent(props.content);
  const author = userProfiles.get(props.user_id)?.name;

  return (
    <>
      <h1>{post?.title || props.title}</h1>
      <h2>{post?.subtitle || props?.subtitle}</h2>
      <FlexRow size='xxs' justify='space-between'>
        <FlexRow size='xxs'>
          <FullNameAvatar>{initialValue(author)}</FullNameAvatar>
          <FlexColumn align='start' size='xxs'>
            <div style={{ fontWeight: 600, fontSize: '16px',textTransform:'capitalize' }}>{author}</div>
            <PublishDate>
              {props.institute_name ? `${props.institute_name} · ${format(props.published_date_time)}`: `${format(props.published_date_time)}`}
            </PublishDate>
          </FlexColumn>
        </FlexRow>
        <EventSchedule {...props} />
      </FlexRow>
      <StyledDivider style={{marginBottom:20}}/>
      <PostActions {...props} enablePostAction={true} />
      <StyledDivider style={{marginTop:20}}/>
      <div dangerouslySetInnerHTML={{ __html: post.content }} style={{marginBottom:'25px', wordWrap: 'break-word'}}></div>
    </>
  );
}

function PostActions(props: PostActionsProps) {
  const { user } = useAccountStore();
  const { addReaction, removeReaction, fetchPost,count } = usePostViewStore();
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  const reaction = props.reactions.collection.find(r => r.user_id.toLowerCase() === user?.userId?.toLowerCase() && r.type === 'Like');
  const isLiked = Boolean(reaction);

  const handleLike = () => {
    if (reaction?.reaction_id) {
      removeReaction(props.post_id, reaction.reaction_id);
    } else {
      addReaction(props.post_id);
    }
  };

  const toggleDrawer = (open: boolean) => {
    setOpenDrawer(open);
  };

  async function handleDataFromChild() {
    await fetchPost(props.post_id,true);
  }

  function closeComment(){
    toggleDrawer(false);
  }

  //comment section
  return (
    <FlexRow
      size='lg'
      justify='space-between'
      style={{ margin: '20px 0', background:'unset' }}
    >
      <FlexRow size='lg' style={{background:'unset'}}>
        {props?.is_allow_reactions && (<FlexRow
          size='xs'
          style={{ cursor: 'pointer',background:'unset' }}
          onClick={props.enablePostAction ? handleLike : undefined}
        >
          {isLiked && <LikeFilledIcon />}
          {!isLiked && <ThumbsUpIcon />}
          {props.reactions.metadata[0]?.count}
        </FlexRow>)}
        {props?.is_allow_comments && 
          (
            <FlexRow size='xs' style={{ cursor: 'pointer',background:'unset'}}>
              <span onClick={props.enablePostAction ? () => toggleDrawer(true) : undefined} style={{marginTop:'5px'}}>
                <MessageIcon />
              </span>
              <span onClick={props.enablePostAction ? () => toggleDrawer(true) : undefined}>{count >= 0 ? count : props.comments_count}</span>
              
            </FlexRow>
          )
        }
        <Drawer
          anchor='right'
          open={openDrawer}
          onClose={() => toggleDrawer(false)}
        >
          <CommentsSection
            sendPostComments={props?.post_id}
            user={user.userId}
            sendDataToParent={handleDataFromChild}
            isClose={closeComment}
          />
        </Drawer>
      </FlexRow>
      <FlexRow
        size='xs'
        style={{ marginRight: '10%', cursor: 'pointer',background:'unset'}}
      >
        <ShareMenu {...props}/>
      </FlexRow>
    </FlexRow>
  );
}

function PostDetail() {
  const { post, fetchPost, isLoading, relatedPosts } = usePostViewStore();
  const { routePath } = useParams();
  const postId = routePath.slice(-36);

  useEffect(() => {
    fetchPost(postId);
    window.scrollTo(0,0);
  }, [routePath]);

  return (
    <>
      {isLoading ? (<DetailView />) : (
        <>{post && (
          <>
            <Container>
              <PostContentView {...post} />
              <PostActions {...post} enablePostAction={true} />
            </Container>
            {relatedPosts?.length>0 && <RelatedContents />}
          </>
        )}
        </>
      )}
    
    </>
  );
}

function RelatedContents() {
  const { relatedPosts, userProfiles} = usePostViewStore();
  const navigate = useNavigate();

  return (
    <RelatedContainer size='sm' justify='center' align='center'>
      <FlexColumn size='xs' align='start'>
        <h2 style={{ margin: '50px 0' }}>Related Posts</h2>
        <ContentWrapper size='sm' justify='space-around'>
          {relatedPosts?.map(post => {
            const user = userProfiles.get(post.user_id);
            return (
              <PostCard key={post.post_id}>
                {post.cover_image_link && (<PostImage src={post.cover_image_link} alt={post.title} onClick={() => navigate(structureViewPostUrl(post?.route_path, post?.institute_name))} />)}
                <PostContent>
                  <PostAuthor size='xxs' align='center'>
                    <AuthorAvatar>{initialValue(user?.name)}</AuthorAvatar>
                    <div>
                      <AuthorName>{userProfiles.get(post.user_id)?.name}</AuthorName>
                    </div>
                  </PostAuthor>
                  <PostTitle onClick={() => navigate(structureViewPostUrl(post?.route_path, post?.institute_name))}>{post.title}</PostTitle>
                  <PostSubtitle onClick={() => navigate(structureViewPostUrl(post?.route_path, post?.institute_name))}>{post.subtitle}</PostSubtitle>
                </PostContent>
                <PostActions {...post} enablePostAction={false} />
              </PostCard>
            );
          })}
        </ContentWrapper>
      </FlexColumn>
    </RelatedContainer>
  );
}

interface PostContent {
  title?: string;
  subtitle?: string;
  content: string;
}

function parseContent(content: string) {
  const result = { content: '' } as PostContent;

  const parser = new DOMParser();
  const doc = parser.parseFromString(content, 'text/html');
  const tags = Array.from(doc.body.childNodes).slice(0, 2);

  if (tags[0] instanceof HTMLHeadingElement) {
    result.title = tags[0].innerText;
    doc.body.removeChild(tags[0]);

    if (tags[1] instanceof HTMLHeadingElement) {
      result.subtitle = tags[1].innerText;
      doc.body.removeChild(tags[1]);
    }
  }

  result.content = doc.body.innerHTML;

  return result;
}

export default PostDetail;
