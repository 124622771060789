import React, { useEffect, useState } from 'react';
import { DateRange } from 'react-date-range';
import moment from 'moment';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { MenuItem, Select, FormControl, Box, InputAdornment, IconButton } from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid';
import { useMyContentPostStore } from 'store/myContentStore';


function DatePicker( {setDateFilter, setIsDateRangeSelected} ) {
  const [date, setDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });
  const [dateRangeOption, setDateRangeOption] = useState('Select Date');
  const [isOpen, setIsOpen] = useState(false);
  const [customDateLabel, setCustomDateLabel] = useState('Custom Date');
  const { resetMyContentList } = useMyContentPostStore();

  const handleOptionChange = (event) => {
    setDateRangeOption(event.target.value);
    switch (event.target.value) {
    case '1 week':
      setDate({
        endDate: moment().toDate(),
        startDate: moment().subtract(7, 'days').toDate(),
        key: 'selection',
      });
      setIsDateRangeSelected(true);
      break;
    case '1 month':
      setDate({
        endDate: moment().toDate(),
        startDate: moment().subtract(1, 'month').toDate(),
        key: 'selection',
      });
      setIsDateRangeSelected(true);
      break;
    case 'custom':
      setIsOpen(true);
      setDate({
        endDate: moment().toDate(),
        startDate: moment().toDate(),
        key: 'selection',
      });
      break;
    default:
      break;
    }
  };

  const handleDateChange = ranges => {
    const { startDate, endDate } = ranges.selection;
    if (startDate > endDate) {
      setDate({
        startDate: endDate,
        endDate: startDate,
        key: 'selection',
      });
    } else {
      setDate(ranges.selection);
    }
    if(startDate!== endDate){
      setIsOpen(false);
      setIsDateRangeSelected(true);
    }
  };

  const handleCustomClick=()=>{
    setIsDateRangeSelected(false);
    setIsOpen(true);
  };

  const handleClear = () => {
    setIsDateRangeSelected(false);
    setIsOpen(false);
    setDateRangeOption('Select Date');
  };
  
  useEffect(() => {
    if (!isOpen) {
      resetMyContentList();
      const formattedStartDate = moment(date.startDate).format('YYYY-MM-DD');
      const formattedEndDate = moment(date.endDate).format('YYYY-MM-DD');
      setDateFilter({ from: formattedStartDate, to: formattedEndDate });
      if (dateRangeOption === 'custom') {
        setCustomDateLabel(`${formattedStartDate} to ${formattedEndDate}`);
      }
    } 
  }, [date, dateRangeOption]);

  return (
    <Box
      sx={{
        width: '250px',
        margin: '0 auto',
        textAlign: 'center',
        position: 'absolute',
        '.MuiSelect-select': {
          padding: '0px',
          marginLeft: '6px',
        },
        '.MuiInputBase-root': {
          height: '28px',
          textAlign: 'left',
          fontFamily: 'Poppins',
          fontSize: '14px',
        },
        '.rdrCalendarWrapper': {
          marginLeft: '-30px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)',
        },
        '.MuiSelect-icon': {
          display: dateRangeOption !== 'Select Date' ? 'none' : 'block',
        },
      }}
    >
      <FormControl fullWidth>
        <Select
          labelId='date-range-label'
          value={dateRangeOption}
          onChange={handleOptionChange}
          renderValue={(selected) => selected === 'custom' ? customDateLabel : selected}
          endAdornment={
            dateRangeOption !== 'Select Date' && (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onClick={handleClear}
                >
                  <GridCloseIcon />
                </IconButton>
              </InputAdornment>
            )
          }
        >
          <MenuItem value='1 week'>1 Week</MenuItem>
          <MenuItem value='1 month'>1 Month</MenuItem>
          <MenuItem onClick={handleCustomClick} value='custom'>Custom Date</MenuItem>
        </Select>
      </FormControl>
      {isOpen && dateRangeOption === 'custom' && (
        <DateRange showDateDisplay={false} ranges={[date]} onChange={handleDateChange} />
      )}
    </Box>
  );
}

export default DatePicker;
