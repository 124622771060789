import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import { usePostViewStore } from 'store/post-view';
import { FlexRow } from 'views/component/styled/flex';

const Link = styled('a')({
  display: 'flex',
  textDecoration: 'none',
  gap: 2,
  alignItems: 'flex-end',
  paddingTop: 16,
  paddingBottom: 16
});

const Developer = styled('span')({
  color: '#9A9696',
  textTransform: 'uppercase',
  fontWeight: 600,
  fontSize: 10,
  height: 12
});

const Logo = styled('img')({
  height: 16
});

function FooterBar() {
  const location = useLocation();
  const { relatedPosts } = usePostViewStore();

  return (
    <FlexRow size='xxs' justify='center' color={location.pathname === '/' || location.pathname.includes('home') ? '#f1f6ff' : relatedPosts?.length > 0 ? '#F9F9F9' : 'white'}>
      <Link href='https://aveoninfotech.com' target='_blank' rel='noreferrer'>
        <Developer>
          developed by
        </Developer>
        <Logo src='/Aveon-Logo.png' alt='logo' />
      </Link>
    </FlexRow>
  );
}

export default FooterBar;
