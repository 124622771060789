export default function invariant(
  message?: string
) {

  throw new Error(
    'Internal Lexical error: invariant() is meant to be replaced at compile ' +
    'time. There is no runtime version. Error: ' +
    message,
  );
}
