import Card from '@mui/material/Card';
import './CreateLanding.scss';
import {
  PostLandingAdvertisementIcon,
  PostLandingEventIcon,
  NewsIcon,
  PostIcon,
} from 'views/component/element/Icons';
import { useNavigate } from 'react-router-dom';

const cardOptions = [
  { icon: <PostIcon />, text: 'Post', path: '/post/new' },
  { icon: <PostLandingEventIcon />, text: 'Event', path: '/event/new' },
  { icon: <NewsIcon />, text: 'News', path: '/news/new' },
  {
    icon: <PostLandingAdvertisementIcon />,
    text: 'Advertisement',
    path: '/advertisement/new',
  },
];

function CreateLanding() {
  const navigate = useNavigate();

  const navigateTo = (path: string) => {
    navigate(path);
  };

  return (
    <div className='create-landing'>
      <div className='create-info'>
        <h1>Create</h1>
        <div className='description'>
          Choose any of the below options to create content
        </div>
      </div>
      <div className='create-cards'>
        {cardOptions.map((option, index) => (
          <Card key={index}>
            <div
              className='card-content'
              onClick={() => navigateTo(option.path)}
            >
              {option.icon}
              <div className='post-text'>{option.text}</div>
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
}

export default CreateLanding;
