import {
  PostCreateOptions,
  MyPostDetailsDto,
  PostDto,
  PostListParams,
  PostActionDto,
  TagDto,
  PostCollectDto,
} from 'models/post';
import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
  putRequest,
  sendRequest,
} from './gateway';
import { Paged } from 'models/Pagination';
import { UserProfileDto } from 'models/UserProfile';
import { capitalizeFirstLetter } from 'helper';
import { EntityResult } from 'models/ApiResult';

async function listPosts(params: PostListParams) {
  const postsPage = await sendRequest(
    getRequest<Paged<PostDto>>('/v1/post/list', { params })
  );
  return postsPage;
}

async function getPost(params: PostListParams = {}) {
  if (!params) {
    params = {};
  }

  const fetchPromise = getRequest<Paged<PostDto>>('/v1/post/list', { params });

  const postList = await sendRequest(fetchPromise);

  const setOfUserIds: string[] = Array.from(
    new Set(postList?.collection?.map(user => user.user_id))
  );
  const listOfUserIds: string[] = [];
  setOfUserIds.forEach(userId => {
    listOfUserIds.push(userId);
  });

  const postListWithUserName: PostDto[] = [];
  postListWithUserName.push(...postList.collection);
  if (listOfUserIds?.length) {
    const userProfileList: any = await listUserProfiles(
      listOfUserIds
    );
    postListWithUserName.forEach(post => {
      const matchingUserProfile = userProfileList?.data?.find(userProfile => {
        return userProfile.user_id.toLowerCase() == post.user_id.toLowerCase();
      });
      if (matchingUserProfile) {
        post.user_name = capitalizeFirstLetter(matchingUserProfile.name);
      }
    });
  }
  return postListWithUserName;
}

async function createPost(postData: PostCreateOptions) {
  const fetchPromise = postRequest<EntityResult<PostDto>>('/v1/post', postData);
  return await sendRequest(fetchPromise);
}

async function getMyPostDetails(params: PostListParams) {
  let url = `/v1/post/list/me?limit=${params.limit}&page=${params.page}&type=${params.type}&statuses=${params.statuses}`;
  if (params.fromDate) {
    url += `&from=${params.fromDate}`;
  }
  if (params.toDate) {
    url += `&to=${params.toDate}`;
  }
  const fetchPromise = getRequest<Paged<PostCollectDto>>(url);
  return await sendRequest(fetchPromise);
}

async function getMyPostDetailsStore(params: PostListParams) {
  const queryString = [`limit=${params.limit}`, `page=${params.page}`];
  if (params.query) {
    queryString.push(`query=${params.query}`);
  }
  if (params.tagId) {
    queryString.push(`tagId=${params.tagId}`);
  }
  const query = queryString.join('&');
  const fetchPromise = getRequest<MyPostDetailsDto>(
    `/v1/post/list?${query}`
  );
  return await sendRequest(fetchPromise);
}

async function getParticularPostDetails(postId: string) {
  const fetchPromise = getRequest<PostDto>(`/v1/post/${postId}`);
  return await sendRequest(fetchPromise);
}

async function archivePost(postId: string) {
  const fetchArchivePostPromise = patchRequest<EntityResult<any>>(
    `/v1/post/${postId}/archive`,
    { skipSpinner: true }
  );
  return await sendRequest(fetchArchivePostPromise);
}

async function deleteDraftPost(postId: string) {
  const fetchDeleteUserPromise = deleteRequest<EntityResult<any>>(
    `/v1/post/${postId}`,
    { skipSpinner: true }
  );
  return await sendRequest(fetchDeleteUserPromise);
}

async function publishDraftPost(postId: string) {
  const fetchArchivePostPromise = patchRequest<EntityResult<any>>(
    `/v1/post/${postId}/publish`
  );
  return await sendRequest(fetchArchivePostPromise);
}

async function updatePost(postId: string, postData: PostCreateOptions) {
  const fetchPromise = putRequest<EntityResult<PostDto>>(
    `/v1/post/${postId}`,
    postData
  );
  return await sendRequest(fetchPromise);
}

async function fetchTagBasedPost(query: string, limit: number, page: number) {
  const fetchPromise = getRequest<any>(
    `v1/post/list?query=${query}&limit=${limit}&page=${page}`
  );
  const tagBasedPostListWithUserName = await sendRequest(fetchPromise);

  const setOfUserIds: string[] = Array.from(
    new Set(
      tagBasedPostListWithUserName.data.collection?.map(user => user.user_id)
    )
  );

  const listOfUserIds: string[] = [];
  setOfUserIds.forEach(userId => {
    listOfUserIds.push(userId);
  });

  if (listOfUserIds?.length) {
    const userProfileList: any = await listUserProfiles(
      listOfUserIds
    );
    tagBasedPostListWithUserName.data.collection.forEach(post => {
      const matchingUserProfile = userProfileList?.data?.find(userProfile => {
        return userProfile.user_id.toLowerCase() == post.user_id.toLowerCase();
      });
      if (matchingUserProfile) {
        post.user_name = capitalizeFirstLetter(matchingUserProfile.name);
      }
    });
  }

  return tagBasedPostListWithUserName;
}

async function createTags(tags: string[]) {
  const fetchPromise = postRequest<TagDto[]>('/v1/tag', { tags });
  return await sendRequest(fetchPromise);
}

async function addUserReaction(postId: string) {
  const fetchPromise = postRequest<any>(
    `/v1/post/${postId}/reaction`,
    { reaction_type: 'Like' },
    { skipSpinner: false }
  );
  return await sendRequest(fetchPromise);
}

async function deleteUserReaction(postId: string, reactionId: string) {
  const fetchDeleteUserReactionPromise = deleteRequest<any>(
    `/v1/post/${postId}/reaction/${reactionId}`,
    { skipSpinner: false }
  );
  return await sendRequest(fetchDeleteUserReactionPromise);
}

async function getPostByPostId(postId: string) {
  const fetchPostInformationPromise = getRequest<PostDto>(
    `/v1/post/${postId}`,
    { skipSpinner: false }
  );
  return await sendRequest(fetchPostInformationPromise);
}

async function publishPost(postId: string, options: PostActionDto) {
  const fetchPostInformationPromise = patchRequest<any>(
    `/v1/post/${postId}/publish`,
    options,
    { skipSpinner: true }
  );
  return await sendRequest(fetchPostInformationPromise);
}

async function schedulePost(postId: string, options: PostActionDto) {
  const fetchPostInformationPromise = patchRequest<any>(
    `/v1/post/${postId}/schedule`,
    options,
    { skipSpinner: true }
  );
  return await sendRequest(fetchPostInformationPromise);
}

async function listUserProfiles(userIds: string[]) {
  const userProfilesRequest = postRequest<UserProfileDto[]>(
    '/association/api/user/profile/list',
    {
      user_ids: userIds
    }
  );

  return await sendRequest(userProfilesRequest);
}

export {
  getPost,
  getMyPostDetails,
  getParticularPostDetails,
  createPost,
  archivePost,
  deleteDraftPost,
  publishDraftPost,
  updatePost,
  fetchTagBasedPost,
  createTags,
  addUserReaction,
  deleteUserReaction,
  getPostByPostId,
  getMyPostDetailsStore,
  publishPost,
  schedulePost,
  listPosts,
  listUserProfiles
};
