import {
  Avatar,
  Card,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Input,
  TextField,
  styled,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { usePostStore } from 'store/post';
import './PostSettings.scss';
import { initialValue } from 'gateways/util';
import { useAccountStore } from 'store/account';
import { FlexColumn, FlexRow } from 'views/component/styled/flex';
import { Button, Form } from 'views/component/element';
import { PostCreateOptions } from 'models/post';
import ConfirmationDialog from 'views/dialogs/confirmation-dialog';
import FieldTagAutocomplete from 'views/component/element/TagAutocomplete';

const PostCard = styled(Card)({
  padding: '0 16px',
  boxShadow: '0px 1px 8px 0 rgba(0, 0, 0, 0.25)',
  border: '1px solid #E5E5E5',
  borderRadius: '2px',
  display: 'flex',
  justifyContent: 'space-between',
  cursor: 'not-allowed',
  marginTop: '26px',
  minHeight: '165px',
});

const NameAvatarSingleLetter = styled(Avatar)({
  height: '20px',
  width: '20px',
  backgroundColor: '#242424',
  fontSize: '10px',
});

export const FullNameAvatar = styled(Avatar)({
  height: '66px',
  width: '66px',
  backgroundColor: '#242424',
  fontSize: '24px',
  fontFamily: 'Rubik',
  marginTop: '16px',
  marginRight: '24px',
});

export const StyledDivider = styled(Divider)({
  marginTop: '40px',
  marginBottom: '40px',
});

const InputField = styled(Input)({
  fontFamily: 'Poppins',
  fontSize: '13px',
  width: '-webkit-fill-available',
});

const LeftSection = styled(FlexColumn)({
  position: 'fixed',
  top: '26px',
  height: 'auto',
  paddingLeft: '281px',
  marginRight: '107px',
  marginTop: '74px',
  width: 'max-content',
  placeItems: 'start',
  '@media (max-width: 1500px) and (min-width: 1281px)': {
    paddingLeft: '81px',
  },
  '@media (max-width: 1280px) and (min-width: 769px)': {
    paddingLeft: '40px',
  },
  '@media (max-width: 768px)': {
    paddingLeft: '20px',
  },
});

const MainSection = styled('div')({
  paddingRight: '281px',
  maxHeight: 'auto',
  flexGrow: '1',
  marginLeft: '30%',
  '@media (max-width: 1500px) and (min-width: 1281px)': {
    paddingRight: '80px',
  },
  '@media (max-width: 1280px) and (min-width: 769px)': {
    paddingRight: '40px',
  },
  '@media (max-width: 768px)': {
    paddingRight: '10px',
  },
});

const SectionHeader = styled('div')({
  fontSize: '20px',
  fontWeight: '600',
  color: '#242424',
});

const PostTitle = styled('div')({
  paddingBottom: '12px',
  fontSize: '16px',
  fontWeight: '600',
});

const PostContent = styled('div')({
  fontSize: '13px',
});

const PostImage = styled('img')({
  margin: '31px 16px 12px 32px',
  height: '122px',
  width: '147px',
});

const InnerTitle = styled('div')({
  fontSize: '14px',
  fontWeight: 500,
});

const Options = styled('div')({
  cursor: 'pointer',
});

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: 'none',
    },
  },
});

const PostSettings = () => {
  const { postId } = useParams();
  const { getPost, post, saveDraft, extractImagesAndTexts} = usePostStore();
  const { user } = useAccountStore();
  const previewRef = useRef(null);
  const authorRef = useRef(null);
  const seoRef = useRef(null);
  const readerRef = useRef(null);
  const advancedRef = useRef(null);
  const [seoTitle, setSeoTitle] = useState('');
  const [seoDescription, setSeoDescription] = useState('');
  const [regionState, setRegionState] = useState('');
  const [isAllowComments, setIsAllowComments] = useState(false);
  const [isAllowReactions, setIsAllowReactions] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const navigate = useNavigate();

  const postForm = {
    tags: post?.tags
  };

  useEffect(()=>{
    postForm.tags = post?.tags;
  },[post?.tags]);

  const handleSaveTag = async (values: typeof postForm) => {
    const model = {
      tags: values.tags,
    } as PostCreateOptions;
    await saveDraft(model);
  };

  const handleSaveSeo = async (field = {}) => {
    const model = {
      ...field,
      is_seo_synthesized: false 
    } as PostCreateOptions;
    await saveDraft(model);
  };

  const scrollToSection = ref => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  const parseContent = (content: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');
    return doc.body.textContent;
  };

  const truncateContent = (content: string, maxLength = 150): string => {
    if (content?.length > maxLength) {
      return content.substring(0, maxLength - 3) + '...';
    }
    return content;
  };

  const handleSeoChange = (setter, maxLength) => e => {
    let newValue = e.target.value;
    if (newValue.length > maxLength) {
      newValue = newValue.substring(0, maxLength);
    }
    setter(newValue);
  };

  const handleAdvancedSettings = async () => {
    const model = {
      is_allow_reactions: isAllowReactions,
      is_allow_comments: isAllowComments,
      region: regionState,
    } as PostCreateOptions;
    setRegionState('');
    await saveDraft(model);
  };

  const handleClickOpen = () => {
    setOpenConfirm(true);
  };

  const handleConfirmClose = event => {
    if (event.isContentRemoved) {
      navigate('/me/contents');
    }
    setOpenConfirm(false);
  };

  useEffect(() => {
    if (postId) {
      extractImagesAndTexts();
      getPost(postId);
    }
  }, [postId]);
 
  useEffect(() => {
    setSeoTitle(post?.seo_title);
    setSeoDescription(post?.seo_description);
    setIsAllowComments(post?.is_allow_comments);
    setIsAllowReactions(post?.is_allow_reactions);
    setRegionState(post?.region);
  }, [post]);

  return (
    <FlexRow size='xs'>
      <LeftSection size='sm' align='center'>
        <SectionHeader>Post Settings</SectionHeader>
        <Options onClick={() => scrollToSection(previewRef)}>
          {' '}
          Post Preview
        </Options>
        <Options onClick={() => scrollToSection(authorRef)}> Author</Options>
        <Options onClick={() => scrollToSection(readerRef)}>
          {' '}
          Reader Interests
        </Options>
        <Options onClick={() => scrollToSection(seoRef)}> SEO Settings</Options>
        <Options onClick={() => scrollToSection(advancedRef)}>
          {' '}
          Advanced Settings
        </Options>
      </LeftSection>
      <MainSection>
        <div className='page_path'>
          <p>
            <span className='title' onClick={() => navigate(`/post/${post.post_id}/edit`)}>{post?.title}</span>
            <span> &gt; Post Settings</span>
          </p>
        </div>
        <FlexColumn size='xxs' ref={previewRef}>
          <SectionHeader>Post Preview</SectionHeader>
          <PostCard>
            <FlexColumn size='xxs'>
              <FlexRow size='xxs' align='center'>
                <NameAvatarSingleLetter>
                  {user?.name[0].toLocaleUpperCase()}
                </NameAvatarSingleLetter>
                <p style={{ fontSize: '12px',textTransform:'capitalize' }}>{user?.name}</p>
              </FlexRow>
              <PostTitle>{post?.title}</PostTitle>
              <PostContent>
                {truncateContent(parseContent(post?.content))}
              </PostContent>
            </FlexColumn>
            {post?.cover_image_link ? <PostImage src={post?.cover_image_link}></PostImage>
              : <PostImage style={{
                margin: '31px 16px 12px 32px',
                height: '122px', width: '0px'
              }} src={post?.cover_image_link}></PostImage>
            }
          </PostCard>
        </FlexColumn>
        <StyledDivider></StyledDivider>
        <FlexColumn size='xxs' ref={authorRef}>
          <SectionHeader>Author</SectionHeader>
          <FlexRow size='xxs'>
            <FullNameAvatar>{initialValue(user?.name)}</FullNameAvatar>
            <FlexColumn align='start' size='xxs'>
              <div style={{ fontWeight: 600, fontSize: '20px',textTransform:'capitalize' }}>
                {user?.name}
              </div>
              <div style={{ fontWeight: 500, fontSize: '14px' }}>
                {user?.email}
              </div>
            </FlexColumn>
          </FlexRow>
        </FlexColumn>
        <StyledDivider></StyledDivider>
        <FlexColumn size='sm' ref={readerRef}>
          <SectionHeader>Reader Interests</SectionHeader>
          <InnerTitle>Topics</InnerTitle>
          <div style={{ fontSize: '13px' }}>
            Add topics (up to 5) so readers know what your story is about
          </div>
          <Form initialValues={postForm} onSubmit={handleSaveTag} validate={handleSaveTag}>
            {() => <><FieldTagAutocomplete name='tags' /></>}
          </Form>
         
        </FlexColumn>
        <StyledDivider></StyledDivider>
        <FlexColumn size='sm' ref={seoRef}>
          <SectionHeader>SEO Settings</SectionHeader>
          <InnerTitle>SEO Title</InnerTitle>
          <PostContent>
            The SEO Title is used in place of your Title on search engine
            results pages, such as a Google search. SEO titles over 60
            characters will be truncated. SEO titles between 40 and 50
            characters with commonly searched words have the best
            click-through-rates.
          </PostContent>
          <div
            style={{ fontSize: '14px' }}
          >{`Title Preview (${seoTitle?.length ?? 0})`}</div>
          {/* <FlexRow size='xs'> */}
          <InputField
            value={seoTitle}
            onChange={handleSeoChange(setSeoTitle, 60)}
            placeholder={'Your SEO Title here...'}
          ></InputField>
          {/* </FlexRow> */}
          <Button
            style={{ width: 'fit-content' }}
            color='success'
            type='submit'
            onClick={() =>
              handleSaveSeo({ seo_title: seoTitle})
            }
          >
            {' '}
            Save{' '}
          </Button>
          <InnerTitle>SEO Description</InnerTitle>
          <PostContent>
            The SEO Description is used in place of your Subtitle on search
            engine results pages. Good SEO descriptions utilize keywords,
            summarize the story and are between 140-156 characters long.
          </PostContent>
          <div
            style={{ fontSize: '14px' }}
          >{`Description (${seoDescription?.length ?? 0}):  `}</div>
          <StyledTextField
            variant='standard'
            multiline
            rows={4}
            value={seoDescription}
            onChange={handleSeoChange(setSeoDescription, 330)}
            placeholder={'Your SEO Description here...'}
          ></StyledTextField>
          <Button
            style={{ width: 'fit-content' }}
            color='success'
            type='submit'
            onClick={() => handleSaveSeo({ seo_description: seoDescription })}
          >
            {' '}
            Save{' '}
          </Button>
        </FlexColumn>
        <StyledDivider></StyledDivider>
        <FlexColumn size='sm' ref={advancedRef}>
          <SectionHeader>Advanced Settings</SectionHeader>
          <FlexColumn size='xs'>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isAllowComments}
                    onChange={e => setIsAllowComments(e.target.checked)}
                  />
                }
                label='Allow Comments'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isAllowReactions}
                    onChange={e => setIsAllowReactions(e.target.checked)}
                  />
                }
                label='Allow Reactions'
              />
            </FormGroup>
            <InputField
              value={regionState}
              onChange={e => setRegionState(e.target.value)}
              placeholder='Add the region...'
            ></InputField>
          </FlexColumn>
          <Button
            onClick={handleAdvancedSettings}
            style={{ width: 'fit-content' }}
            color='success'
            type='submit'
          >
            {' '}
            Save{' '}
          </Button>
        </FlexColumn>
        <StyledDivider></StyledDivider>
        <FlexColumn size='sm'>
          <SectionHeader>Delete Post</SectionHeader>
          <Button
            style={{ width: 'fit-content', marginBottom: '50px' }}
            color='error'
            onClick={handleClickOpen}
          >
            {' '}
            Delete{' '}
          </Button>
        </FlexColumn>
        <ConfirmationDialog
          open={openConfirm}
          onClose={handleConfirmClose}
          post={post}
        />
      </MainSection>
    </FlexRow>
  );
};

export default PostSettings;
