import styled from '@emotion/styled';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { archivePost, deleteDraftPost } from 'gateways/post';
import { PublishType } from 'models/post';
import { Button } from 'views/component/element';

type ConfirmationDialogProps = {
  open?: boolean;
  onClose: (boolean) => void;
  post?: any;
};

const ContentText = styled(DialogContentText)({
  fontFamily: 'Poppins',
  fontSize:14
});

const Title = styled(DialogTitle)({
  fontFamily: 'Poppins',
});

const ActionButton = styled(Button)({
  fontFamily: 'Poppins',
  fontSize:14
});

const ConfirmationDialog = (props: ConfirmationDialogProps) => {
  async function deletePost() {
    if(props?.post?.status == PublishType.Published){
      await archivePost(props?.post?.post_id);
    }else{
      await deleteDraftPost(props?.post?.post_id);
    }
    const returnProps = {
      postId : props?.post?.post_id,
      isContentRemoved : true
    };
    props.onClose(returnProps);
  }

  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        aria-labelledby='draggable-dialog-title'
      >
        <Title style={{ cursor: 'move' }} id='draggable-dialog-title'>
          Confirmation
        </Title>
        <DialogContent>
          <ContentText>
            Deletion is not reversible, and the post will be completely deleted.
            If you do not want to delete, you can unlist the post.
          </ContentText>
        </DialogContent>
        <DialogActions>
          <ActionButton
            onClick={props.onClose}
            variant='text'
            color='secondary'
          >
            No, Cancel
          </ActionButton>
          <ActionButton onClick={() => deletePost()} color='success'>
            Yes, Delete
          </ActionButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmationDialog;
