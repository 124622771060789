export function capitalizeFirstLetter(userName: string): string {
  return userName.charAt(0).toUpperCase() + userName.slice(1);
}

export function isEmptyString(type: string): boolean {
  return type != null && type != undefined && type.length > 0;
}

export function structureViewPostUrl(
  routePath: string,
  instituteName: string | null
) {
  let viewPostUrl = `/${routePath}`;
  if (instituteName) {
    instituteName = instituteName.replace(/\s/g, '-').toLowerCase();
    viewPostUrl = `/${instituteName}/${routePath}`;
  }
  return viewPostUrl;
}

export const base64ToBlob = (base64, mime) => {
  const byteCharacters = atob(base64);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    byteArrays.push(new Uint8Array(byteNumbers));
  }
  return new Blob(byteArrays, { type: mime });
};

export const blobToFile = (blob, filename) =>
  new File([blob], filename, { type: blob.type });

export const handleBase64ToFile = data => {
  const [mime, base64] = data.split(',');
  const mimeType = mime.match(/:(.*?);/)?.[1] || 'image/png';
  const blob = base64ToBlob(base64, mimeType);
  return blobToFile(blob, 'cover_image.png');
};
