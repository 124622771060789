import { styled } from '@mui/material/styles';
import { useEffect } from 'react';
import { usePostViewStore } from 'store/post-view';
import { CardView } from 'views/component/element';
import { CardLinear } from 'views/component/Loader/Loading';
import { FlexColumn, FlexRow } from 'views/component/styled/flex';
import ReactGA from 'react-ga';

export const Container = styled('div')({
  height: '100%',
  width: '100%',
  flex: '1 1',
});

export const StyledHomePage = styled('div')`
  padding: 20px 0px;
  background: #f1f6ff;

  @media screen and (min-width: 1024px) and (max-width: 1279px) {
    padding: 20px 20px;
  }
`;

export const StyledRow = styled(FlexRow)`
  max-width: 1220px;
  margin: 0px auto;
  background: unset;
  @media screen and (min-width: 1024px) and (max-width: 1279px) {
    column-gap: 10px;
  }
`;

export const StyledHomePageTopView = styled(FlexColumn)({
  marginBottom: '16px',
  alignItems: 'start',
  minWidth: '50%',
});

const PostHome = () => {
  const {
    fetchHomeView,
    posts,
    events,
    news,
    advertisements,
    tagPosts,
    isLoading,
  } = usePostViewStore();

  useEffect(() => {
    if (!posts?.length) {
      fetchHomeView();
    }
    ReactGA.pageview(location.pathname + location.search);
  }, [posts]);

  return (
    <Container>
      <StyledHomePage>
        <StyledRow size='sm' align='start'>
          <StyledHomePageTopView size='sm'>
            {news?.length && !isLoading ? (
              <CardView title='News' collection={news} />
            ) : !news?.length && !isLoading ? (
              ''
            ) : (
              <CardLinear />
            )}
            {posts?.length && !isLoading ? (
              <CardView title='Post' collection={posts} />
            ) : !posts?.length && !isLoading ? (
              ''
            ) : (
              <CardLinear />
            )}

            {tagPosts?.length &&
              !isLoading &&
              tagPosts?.slice(2,tagPosts.length).map(tag => (
                <div key={tag.id}>
                  <CardView
                    title={tag.name}
                    collection={tag.posts}
                    tags={tagPosts}
                    tagId={tag.id}
                  />
                </div>
              ))}
          </StyledHomePageTopView>
          <StyledHomePageTopView size='sm'>
            {advertisements?.length && !isLoading ? (
              <CardView
                title='Sponsored'
                collection={advertisements.filter(
                  res => res?.cover_image_link?.length > 0
                )}
              />
            ) : !advertisements?.length && !isLoading ? (
              ''
            ) : (
              <CardLinear />
            )}

            {events?.length && !isLoading ? (
              <CardView title='Events' collection={events} />
            ) : !events?.length && !isLoading ? (
              ''
            ) : (
              <CardLinear />
            )}
            {tagPosts?.length &&
              !isLoading &&
              tagPosts?.slice(0,2).map(tag => (
                <div key={tag.id} style={{width:'100%'}}>
                  <CardView
                    title={tag.name}
                    collection={tag.posts}
                    tags={tagPosts}
                    tagId={tag.id}
                  />
                </div>
              ))}
          </StyledHomePageTopView>
        </StyledRow>
      </StyledHomePage>
    </Container>
  );
};

export default PostHome;
