import { styled } from '@mui/material/styles';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import { useAccountStore } from 'store/account';
import { usePostStore } from 'store/post';
import { FlexRow } from 'views/component/styled/flex';
import { Button } from 'views/component/element';
import { useEffect, useState } from 'react';
import FooterBar from 'views/component/nav/Footer';
import PublishDialog from 'views/dialogs/publish/PublishDialog';
import ProfileMenu from 'views/component/ProfileMenu';

const Logo = styled('img')({
  width: 194,
  cursor:'pointer'
});

const NavBarContainer = styled(FlexRow)({
  paddingTop: 8,
  paddingBottom: 8,
  width: 1032,
  marginLeft: 'auto',
  marginRight: 'auto',
  justifyContent: 'space-between',
  position: 'sticky',
  top: 0,
  zIndex: 1,
  background: '#ffffff',
  opacity: 0.9,
});

const Draft = styled('div')({
  fontSize: 12,
  fontWeight: 500,
  color: '#242424',
});

const Status = styled('div')({
  fontSize: 12,
  color: '#909090',
});

const Type = styled('div')({
  fontWeight:700,
  fontSize:14,
});

const Pipe = styled('div')({
  color:'#DADCE0'
});

const menuProps = {
  elevation: 0,
  sx: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
};

function NavigationBar() {
  const { isSaved, isSaving, post } = usePostStore();
  const { user } = useAccountStore();
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const navigate= useNavigate();
  const {pathname} = useLocation();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(()=>{
    getPostHeading();
  },[]);

  function getPostHeading():string{
    const type = pathname.split('/')[1];
    switch (type.toLowerCase()) {
    case 'post':
      return 'Social Media Post ';
    case 'news':
      return 'News Article';
    case 'event':
      return 'Event Announcement';
    default:
      return 'Advertisement';
    }
  }

  function handleMoreActions(){
    navigate(`${post.type}/${post?.post_id}/settings`);
  }

  const [openDialog, setOpenDialog] = useState(false);

  const disablePublish = Boolean(!post);

  return (
    <NavBarContainer size='lg'>
      <FlexRow size='md'>
        <Logo src='/logo.png' alt='logo' onClick={()=> navigate('/')}/>
        <Type>{getPostHeading()}</Type>
        <Pipe>|</Pipe>
        <Draft>Draft in {user?.name}</Draft>
        {isSaved && <Status>Saved</Status>}
        {isSaving && <Status>Saving...</Status>}
      </FlexRow>
      <FlexRow size='sm'>
        <Button color='success' size='small' disabled={disablePublish} onClick={()=>setOpenDialog(true)}>
          Publish
        </Button>
        <Button 
          variant='text'
          size='small'
          disableRipple
          onClick={handleClick}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined} 
          disabled={disablePublish}>
          <MoreHoriz />
        </Button>
        <Menu
          anchorEl={anchorEl}
          id='account-menu'
          open={open}
          onClick={handleClose}
          onClose={handleClose}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          PaperProps={menuProps}
        >
          <MenuItem onClick={handleMoreActions}>
          Post Settings
          </MenuItem>
        </Menu>
        <ProfileMenu />
      </FlexRow>
      <PublishDialog  open={openDialog} onClose={()=>setOpenDialog(!openDialog)}/>
    </NavBarContainer>
    
  );
}

const PostEditorLayout = () => {
  return (
    <>
      <NavigationBar />
      <Outlet />
      <FooterBar />
    </>
  );
};

export default PostEditorLayout;
