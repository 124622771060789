import { styled } from '@mui/material/styles';



const SizeMap = { xxs: 4, xs: 8, sm: 16, md: 24, lg: 48, xl: 64 };
type Size = keyof typeof SizeMap;

type FlexProps = { size: Size; align?: string; justify?: string }

export const FlexRow = styled('div', { label: 'row', skipSx: true })<FlexProps>(
  props => ({
    display: 'flex',
    columnGap: SizeMap[props.size],
    alignItems: props.align ?? 'center',
    justifyContent: props.justify ?? 'start',
    background: props.color ?? 'white'
  })
);


export const FlexColumn = styled('div', {
  label: 'column',
  skipSx: true,
})<FlexProps>(props => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: SizeMap[props.size],
  alignItems: props.align ?? 'unset',
  '& h3,& h5': {
    margin: 0,
  },
  '&.small': {
    rowGap: 8,
  },
}));
