import { create } from 'zustand';

interface SpinnerState {
  spinnerShown: boolean;
  events: string[];
}

const useSpinnerStore = create<SpinnerState>(() => ({ spinnerShown: false, events: [] }));

function setSpinnerShown() {
  useSpinnerStore.setState((state) => ({ spinnerShown: Boolean(state.events.length) }));
}

function addEvent(event: string) {
  useSpinnerStore.setState((state) => {
    const events = state.events.concat(event);

    return {
      events,
      spinnerShown: Boolean(event.length)
    };
  });
}

function removeEvent(event: string) {
  useSpinnerStore.setState(state => {
    const events = state.events.slice();
    const index = events.lastIndexOf(event);

    if (index >= 0) {
      events.splice(index, 1);
    }
    return {
      events
    };

  });
  setTimeout(setSpinnerShown, 500);
}

export { useSpinnerStore, addEvent, removeEvent };
