import {
  Box,
  Chip,
  FormControl,
  IconButton,
  Modal,
  TextareaAutosize,
  debounce,
  styled,
} from '@mui/material';
import {
  CancelIcon,
  CancelIconComments,
  CommentsDeleteIcon,
  TickIcon,
  TrashIcon,
  VectorIcon,
} from 'views/component/element/Icons';
import { useEffect, useRef, useState } from 'react';
import {
  deleteComment,
  getComments,
  postComment,
  updateComment,
} from 'gateways/comments';
import { Button, Form } from 'views/component/element';
import { PostListParams } from 'models/post';
import TimeElapsed from 'utils/time-utils';
import './../views/pages/post/PostSettings.scss';
import { FlexColumn, FlexRow } from 'views/component/styled/flex';
import { Avatar } from 'views/component/ProfileMenu';
import { initialValue } from 'gateways/util';
import { StyledDivider } from 'views/component/element/CardView';
import { Count } from 'views/dialogs/publish/PublishDialog';

const filter = { query: '' || '' };
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 632,
  bgcolor: 'background.paper',
  borderRadius: '4px',
  boxShadow: 24,
  p: 4,
  innerHeight: '322px',
  paddingTop: '20px',
  paddingRight: '18px',
  paddingLeft: '18px',
  height: '322px',
};

const StyledButton = styled(FlexRow)({
  marginTop: 20,
  justifyContent: 'space-between',
  marginRight: 27,
  marginBottom: 10,
});

const StyledProfile = styled(FlexRow)({
  flexDirection: 'column',
  alignItems: 'start',
  fontSize: '12px',
  marginLeft: '10px',
});

const StyledProfileColumn = styled(FlexColumn)({
  flexDirection: 'row',
});

const StyledTopRow = styled(FlexRow)({
  justifyContent: 'space-between',
  marginBottom: 10,
});

const StyledName = styled(FlexColumn)({
  fontWeight: 500,
});

const StyledDate = styled(FlexColumn)({
  color: '#6B6B6B',
});

const StyledHeaderName = styled(FlexColumn)({
  height: '24px',
  margin: '16px 0px 0px 0px',
  fontSize: '16px',
  fontWeight: '500',
  color: '#334253',
  flexDirection: 'row',
});

const CommentsSection = props => {
  const [dataList, setData] = useState([]);
  const [comment, addComment] = useState('');
  const [page, setPage] = useState(1);
  const commentsContainerRef = useRef(null);

  //modal details
  const [openConfirmationModal, setOpenConfirm] = useState(false);
  const handleCloseConfirmModal = () => setOpenConfirm(false);
  const handleConfirmModalOpen = () => setOpenConfirm(true);
  const [value, setValue] = useState(null);
  const [isCancelComment, isSetCancelComment] = useState(false);

  function openConfirmation(data) {
    handleConfirmModalOpen();
    setValue(data);
  }

  async function fetchComments(postId) {
    try {
      const params: PostListParams = {
        page: page,
        limit: 100,
      };
      const commentsList = await getComments(postId, params);
      if (commentsList?.length > 0) {
        commentsList.forEach(res => {
          if (res.user_id.toLowerCase() == props.user.toLowerCase()) {
            res['sameUser'] = true;
          }
        });
      }
      setData(prevData => [...prevData, ...commentsList]);
    } catch (error) {
      // TO DO: replace with inline error
      // toastError(error);
    }
  }

  const handleSubmit = async () => {
    event.preventDefault();
    const commentModel = { content: comment };
    await postComment(props.sendPostComments, commentModel);
    addComment('');
    setTimeout(async () => {
      const commentsList = await getComments(props.sendPostComments);
      if (commentsList?.length > 0) {
        commentsList.forEach(res => {
          if (res.user_id.toLowerCase() == props.user.toLowerCase()) {
            res['sameUser'] = true;
          }
        });
      }
      setData(commentsList);
      props.sendDataToParent(commentsList.length.toString());
    }, 1000);
  };

  function closeComments() {
    props.isClose();
  }

  const debouncedCommentsHandleScroll = useRef(
    debounce(() => {
      const container = commentsContainerRef.current;
      if (container) {
        const scrollTop = container.scrollTop;
        const scrollHeight = container.scrollHeight;
        const clientHeight = container.clientHeight;
        if (scrollTop + clientHeight >= scrollHeight - 10) {
          setPage(prevPage => prevPage + 1);
        }
      }
    }, 500)
  ).current;

  async function deleteUserComment(data) {
    handleCloseConfirmModal();
    await deleteComment(data.post_id, data.comment_id);
    setTimeout(async () => {
      const params: PostListParams = {
        page: page,
        limit: 100,
      };
      const commentsList = await getComments(props.sendPostComments,params);
      if (commentsList?.length > 0) {
        commentsList.forEach(res => {
          if (res.user_id.toLowerCase() == props.user.toLowerCase()) {
            res['sameUser'] = true;
          }
        });
      }
      setData(commentsList);
      props.sendDataToParent(commentsList.length.toString());
    }, 500);
  }

  useEffect(() => {
    fetchComments(props.sendPostComments);
  }, [page]);

  useEffect(() => {
    const container = commentsContainerRef.current;
    if (container) {
      container.addEventListener('scroll', debouncedCommentsHandleScroll);
      return () =>
        container.removeEventListener('scroll', debouncedCommentsHandleScroll);
    }
  }, [page]);

  //inline edit

  const [editStates, setEditStates] = useState(dataList.map(() => false));
  const [updatedText, setUpdatedText] = useState('');

  const handleEdit = (index, content) => {
    const newEditStates = [...editStates];
    newEditStates[index] = true;
    setEditStates(newEditStates);
    setUpdatedText(content);
  };

  async function handleUpdate(commentId, index, postId) {
    const comment = { content: updatedText };
    if (updatedText == null || updatedText.trim().length === 0) {
      // TO DO: replace with inline error
      // toastError('Can\'t post an empty comment');
      return;
    }
    await updateComment(postId, commentId, comment);
    const newEditStates = [...editStates];
    newEditStates[index] = false;
    setEditStates(newEditStates);
    setTimeout(async () => {
      const commentsList = await getComments(postId);
      if (commentsList?.length > 0) {
        commentsList.forEach(res => {
          if (res.user_id.toLowerCase() == props.user.toLowerCase()) {
            res['sameUser'] = true;
          }
        });
      }
      setData(commentsList);
      props.sendDataToParent(commentsList.length.toString());
    }, 1000);
  }

  const handleInputChanges = event => {
    setUpdatedText(event.target.value);
  };

  function cancel(index) {
    const newEditStates = [...editStates];
    newEditStates[index] = false;
    setEditStates(newEditStates);
  }

  return (
    <>
      <div
        style={{
          minHeight: '25vh',
          overflowY: 'scroll',
          overflowX: 'hidden',
        }}
      >
        <div className='post_commentsSection'>
          <StyledTopRow size='xs'>
            <StyledHeaderName size='xs'>
              Responses{' '}
              <span>{dataList?.length > 0 ? `(${dataList?.length})` : ''}</span>{' '}
            </StyledHeaderName>
            <FlexColumn size='xs'>
              <IconButton
                edge='start'
                color='inherit'
                aria-label='close'
                onClick={closeComments}
              >
                <CancelIcon />
              </IconButton>
            </FlexColumn>
          </StyledTopRow>
          <Form initialValues={filter} onSubmit={handleSubmit} className='form'>
            <div
              className='post_commentsSectionSend'
              style={{ position: 'sticky', top: 0 }}
            >
              <div className='post_textarea'>
                <FormControl variant='standard'>
                  <TextareaAutosize
                    autoFocus
                    minRows={4}
                    cols={120}
                    value={comment}
                    placeholder='What are your thoughts?'
                    onClick={() => {
                      isCancelComment
                        ? isSetCancelComment(!isCancelComment)
                        : null;
                    }}
                    maxLength={250}
                    onChange={e => addComment(e.target.value)}
                    style={{
                      maxHeight: isCancelComment ? '50px' : '100px',
                      minHeight: isCancelComment ? '30px' : '30px',
                      transition: isCancelComment
                        ? 'max-height 800ms ease 0s'
                        : 'max-height 800ms ease 0s',
                    }}
                  />
                </FormControl>
              </div>
              {!isCancelComment && (
                <StyledButton size='xs'>
                  <div>
                    {comment.length > 230 && (
                      <Count>{comment.length}/250</Count>
                    )}
                  </div>
                  <div>
                    <Button
                      variant='text'
                      color='secondary'
                      style={{ margin: '0 12px' }}
                      onClick={() => {
                        addComment('');
                        isSetCancelComment(!isCancelComment);
                      }}
                    >
                    Cancel
                    </Button>
                    <Button
                      color='success'
                      className='send_btn'
                      disabled={comment.trim().length === 0}
                      type='submit'
                      style={{
                        background: comment.trim().length === 0 ? '#F2F2F2' : '',
                        cursor:
                        comment.trim().length === 0 ? 'not-allowed' : 'pointer',
                        color: comment.trim().length === 0 ? '#909090' : 'white',
                      }}
                    >
                    Respond
                    </Button>
                  </div>
                </StyledButton>
              )}
            </div>
          </Form>

          {dataList.length > 0 && (
            <>
              <StyledDivider style={{ marginBottom: '17px' }} />
              <>
                <div className='post_overall_content'>
                  {dataList.map((data, i) => {
                    return (
                      <>
                        <div
                          key={data.comment_id}
                          className='post_commentsSectionDetails'
                        >
                          <StyledTopRow size='xs'>
                            <StyledProfileColumn size='xs'>
                              <Avatar>{initialValue(data?.user_name)}</Avatar>
                              <StyledProfile size='xs'>
                                <StyledName size='xs'>
                                  {data.user_name}
                                </StyledName>
                                <StyledDate size='xs'>
                                  <TimeElapsed
                                    postedTime={
                                      data.updated_date_time
                                        ? new Date(data.updated_date_time)
                                        : new Date(data.created_date_time)
                                    }
                                  />
                                </StyledDate>
                              </StyledProfile>
                              {data.updated_date_time && (
                                <div
                                  style={{
                                    marginLeft: '12px',
                                    marginTop: '-2px',
                                  }}
                                >
                                  <Chip
                                    style={{ height: 18 }}
                                    label='Edited'
                                    className='edited_chip_layout'
                                  />
                                </div>
                              )}
                            </StyledProfileColumn>
                            {data.sameUser && !editStates[i] && (
                              <StyledProfileColumn size='xs'>
                                <div
                                  style={{
                                    marginRight: '10px',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => handleEdit(i, data.content)}
                                >
                                  <VectorIcon />
                                </div>
                                <div
                                  onClick={() => openConfirmation(data)}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <CommentsDeleteIcon />
                                </div>
                              </StyledProfileColumn>
                            )}
                            {editStates[i] && (
                              <>
                                <div style={{ display: 'flex' }}>
                                  <div
                                    style={{
                                      marginRight: '10px',
                                      cursor: 'pointer',
                                      width: '26px',
                                      height: '26px',
                                    }}
                                    onClick={() =>
                                      handleUpdate(
                                        data.comment_id,
                                        i,
                                        data.post_id
                                      )
                                    }
                                  >
                                    <TickIcon />
                                  </div>
                                  <div
                                    style={{
                                      marginRight: '10px',
                                      cursor: 'pointer',
                                      width: '26px',
                                      height: '26px',
                                    }}
                                    onClick={() => cancel(i)}
                                  >
                                    <CancelIconComments />
                                  </div>
                                </div>
                              </>
                            )}
                          </StyledTopRow>
                          {!editStates[i] ? (
                            <div className='post_details'>{data.content}</div>
                          ) : (
                            <div className='post_textarea'>
                              <FormControl variant='standard'>
                                <TextareaAutosize
                                  minRows={5}
                                  maxRows={5}
                                  cols={2}
                                  value={updatedText}
                                  onChange={handleInputChanges}
                                />
                              </FormControl>
                            </div>
                          )}
                        </div>
                        <StyledDivider
                          style={{ marginTop: 0, marginBottom: 10 }}
                        />
                      </>
                    );
                  })}
                </div>
              </>
            </>
          )}
        </div>
      </div>
      {/* confirmation modal */}
      <Modal open={openConfirmationModal}>
        <Box sx={style}>
          <div
            className='cancel_icon'
            onClick={handleCloseConfirmModal}
            style={{ textAlign: 'end', cursor: 'pointer' }}
          >
            <CancelIcon />
          </div>
          <div style={{ display: 'ruby-text' }}>
            <div
              style={{
                textAlign: 'center',
                height: '48px',
                width: '48px',
                background: '#ffe9e8',
                borderRadius: '50%',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  marginTop: '14px',
                  marginLeft: '14px',
                }}
              >
                <TrashIcon />
              </div>
            </div>
          </div>
          <div
            className='confirm_text'
            style={{
              marginTop: '16px',
              textAlign: 'center',
              marginBottom: '64px',
              fontFamily: 'Poppins',
              height: '36px',
              fontSize: '24px',
            }}
          >
            Are you sure you want to delete this comment?
          </div>
          <div
            className='confirm_button'
            style={{
              textAlign: 'center',
              width: '100%',
              display: 'ruby-text',
            }}
          >
            <div className='confirm_cancel' style={{ marginRight: '14px' }}>
              <Button
                variant='outlined'
                style={{
                  border: 'none',
                  background: ' #e1e2fc',
                  fontFamily: 'Poppins',
                }}
                onClick={handleCloseConfirmModal}
              >
                No, Cancel
              </Button>
            </div>
            <div className='confirm_delete'>
              <Button
                variant='outlined'
                style={{
                  border: 'none',
                  background: ' #ffe9e8',
                  fontFamily: 'Poppins',
                }}
                onClick={() => deleteUserComment(value)}
              >
                Yes, delete
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default CommentsSection;
