import { Outlet } from 'react-router-dom';
import TopNavigationBar from 'views/component/nav/TopNavigationBar';
import FooterBar from 'views/component/nav/Footer';

const PostLayout = () => {
  return (
    <>
      <TopNavigationBar />
      <Outlet />
      <FooterBar />
    </>
  );
};

export default PostLayout;
