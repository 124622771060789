import MatCheckbox, { CheckboxProps } from '@mui/material/Checkbox';
import { Field } from 'formik';

export default function Checkbox(props: CheckboxProps & { label: string }) {
  const { name, label, ...rest } = props;
  return (
    <label>
      <Field name={name}>
        {({ field }) => <MatCheckbox {...field} {...rest} />}
      </Field>
      {label}
    </label>
  );
}
