import { CardContent, styled } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import MessageIcon from '@mui/icons-material/Message';
import EventIcon from '@mui/icons-material/Event';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import { FlexRow } from '../styled/flex';
import { dateFormat } from 'utils/time-format';
import SwipeableTextMobileStepper from './SwipeableViews';
import { useNavigate } from 'react-router-dom';
import { PostDto } from 'models/post';
import { useAccountStore } from 'store/account';
import { TagPostDto, usePostViewStore } from 'store/post-view';
import { initialValue } from 'gateways/util';
import { Avatar } from '../ProfileMenu';
import { structureViewPostUrl } from 'helper';

enum PostType {
  Posts = 'Post',
  Events = 'Events',
  News = 'News',
  Sponsored = 'Sponsored',
}

export const StyledCard = styled('div')({
  padding: 16,
  background: 'white',
  borderRadius: 10,
  width: '100%',
});

export const StyledCardHeader = styled(FlexRow)({
  fontSize: 18,
  fontWeight: 500,
  textAlign: 'left',
  color: '#00758c',
  textTransform: 'capitalize',
  cursor: 'pointer',
  width: 'fit-content',
  background:'unset'
});

export const StyledDivider = styled('hr')({
  backgroundColor: '#e6e5e5',
  height: 0.5,
  margin: '17px 0 0px',
  border: 1,
});

export const StyledContentDivider = styled(FlexRow)({
  justifyContent: 'space-between',
});

const StyledTitle = styled('div')({
  margin: '0px 0px 8px',
  fontSize: 14,
  fontWeight: 600,
  color: ' #242424',
  cursor: 'pointer',
  width:'fit-content',
  textOverflow:'ellipsis',
  overflow:'hidden',
  display:'-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
});

const StyledSubTitle = styled('div')({
  margin: '4px 0px 8px 0',
  fontSize: 14,
  color: '#969696',
  height: 'auto',
  cursor: 'pointer',
  width:'fit-content',
  textOverflow:'ellipsis',
  overflow:'hidden',
  display:'-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
});

export const StyledSvgIcon = styled('svg')({
  height: 20,
  width: 20,
  '& path': {
    fill: '#6180be',
  },
});

export const StyledIconContent = styled(FlexRow)({
  fontSize: 11,
});

export const StyledPostImages = styled('div')({
  width: 116,
  height: 100,
});

export const TextMargin = styled('div')({
  fontSize: 12,
  marginTop: 1.5,
  color: '#6d6d6d',
  fontWeight: 600,
});

export const StyledText = styled('div')`
  white-space: nowrap;
  overflow: hidden;
  color: #969696;
  text-transform: capitalize;
`;

const StyledDiv = styled('div')({
  display:'flex',
  marginBottom:6
});

type PostProps = {
  title?: string;
  collection?: PostDto[];
  tags?:TagPostDto[];
  tagId?:number;
};

function Author({ userId,institution }: { userId: string,institution?: string }) {
  const { userProfiles } = usePostViewStore();
  const user = userProfiles.get(userId.toLowerCase());
  return (
    <><Avatar sx={{ height: 20, width: 20 }}>
      <StyledText
        sx={{
          fontSize: 8,
          textAlign: 'center',
          color: '#ffffff',
          marginTop: '1px !important',
        }}
      >
        {initialValue(user?.name)}
      </StyledText>
    </Avatar><StyledText sx={{
      fontSize: 12,
      marginLeft:'6px',
    }}>{user?.name ? user?.name : ''}</StyledText>
    {institution && (<StyledText sx={{
      fontSize: 12,
      marginLeft:'6px',
    }}>{`in ${institution}`}</StyledText>)}
    </>
  );
}

export default function CardView(props: PostProps) {
  const tagList = props?.tags?.length > 0 ? props.tags.map(re=>re?.name) : [];

  const user = useAccountStore();
  const navigate = useNavigate();

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  };

  const formatter = new Intl.DateTimeFormat('en-US', options);

  return (
    <>
      <StyledCard>
        <StyledCardHeader
          size='sm'
          onClick={() =>
            navigate(
              `/over-all-post/${
                props.title == 'Sponsored' ? 'Advertisement' : props.title == 'Events' ? 'Event' : props.title
              }${props?.tagId ? `?tagId=${props.tagId}` : ''}`
            )
          }
        >
          {props.title} <ArrowForwardIosIcon sx={{ width: '15px' }} />
        </StyledCardHeader>
        <StyledDivider /><>
          {props?.collection?.map((data, index) => {
            return (
              <div key={data.post_id}>
                <CardContent
                  style={{ padding: props.title == PostType.Sponsored ? 0 : '16px 0'}}>
                  {![PostType.Sponsored.toString()].includes(props.title) && (
                    
                    <StyledContentDivider size='xs'>
                      <div style={{ width: '100%', display: 'grid' }}>
                        <StyledDiv><Author userId={data.user_id} institution={data?.institute_name}/>
                        </StyledDiv>
                        {![PostType.Sponsored.toString()].includes(
                          props.title
                        ) && (
                          <>
                            <StyledTitle
                              onClick={() => navigate(structureViewPostUrl(data?.route_path, data?.institute_name))}
                            >
                              {data.title}
                            </StyledTitle></>
                        )}
                        {[
                          PostType.Posts.toString(),
                          PostType.News.toString(),
                          PostType.Events.toString(),
                          ...tagList
                        ].includes(props.title) && (
                          <>
                            {[
                              PostType.Posts.toString(),
                              PostType.News.toString(),
                              ...tagList
                            ].includes(props.title) && (
                              <StyledSubTitle
                                onClick={() =>
                                  navigate(structureViewPostUrl(data?.route_path, data?.institute_name))
                                }
                              >
                                {data.subtitle}
                              </StyledSubTitle>
                            )}
                            {/* subtitle */}
                            {[PostType.Events.toString()].includes(
                              props.title
                            ) && (
                              <>
                                <FlexRow size='xs'>
                                  <StyledIconContent size='xs'>
                                    <StyledSvgIcon>
                                      <EventIcon />
                                    </StyledSvgIcon>
                                    <div style={{ color: '#969696',marginTop:2 }}>
                                      {dateFormat(
                                        formatter.format(
                                          new Date(
                                            data?.start_date_time
                                              ? data.start_date_time
                                              : data.created_date_time
                                          )
                                        )
                                      ) +
                                      ' to ' +
                                      dateFormat(
                                        formatter.format(
                                          new Date(
                                            data?.end_date_time
                                              ? data.end_date_time
                                              : data.updated_date_time
                                          )
                                        )
                                      )}
                                    </div>
                                  </StyledIconContent>
                                  {data.venue && (
                                    <StyledIconContent size='xs'>
                                      <StyledSvgIcon>
                                        <LocationOnIcon />
                                      </StyledSvgIcon>
                                      <div style={{ color: '#969696' }}>
                                        {data?.venue}
                                      </div>
                                    </StyledIconContent>
                                  )}
                                </FlexRow>
                                <StyledSubTitle
                                  onClick={() =>
                                    navigate(structureViewPostUrl(data?.route_path, data?.institute_name))
                                  }
                                >
                                  {data.subtitle}
                                </StyledSubTitle>
                              </>
                            )}
                          </>
                        )}
                        <FlexRow size='sm' style={{ marginTop: 10 }}>
                          <StyledIconContent size='xs'>
                            <StyledSvgIcon>
                              <CalendarMonthIcon />
                            </StyledSvgIcon>
                            <div style={{ color: '#969696',marginTop:2 }}>
                              {formatter.format(
                                new Date(data.published_date_time)
                              )}
                            </div>
                          </StyledIconContent>
                          {data?.is_allow_reactions && (<StyledIconContent size='xs'>
                            {data?.reactions?.metadata.map(reactions => (
                              <FlexRow size='xs' key={reactions.type}>
                                {reactions.type === 'Like' && (
                                  <>
                                    {data.reactions?.collection.find(
                                      likedUser =>
                                        likedUser?.user_id.toLowerCase() ===
                                        user.user.userId?.toLowerCase()
                                    ) ? (
                                        <StyledSvgIcon>
                                          <ThumbUpAltIcon />
                                        </StyledSvgIcon>
                                      ) : (
                                        <StyledSvgIcon>
                                          <ThumbUpOffAltIcon />
                                        </StyledSvgIcon>
                                      )}
                                  </>
                                )}
                                <TextMargin>{reactions?.count}</TextMargin>
                              </FlexRow>
                            ))}
                            {data.reactions.metadata.length === 0 && (
                              <FlexRow size='xs'>
                                <StyledSvgIcon>
                                  <ThumbUpOffAltIcon />
                                </StyledSvgIcon>
                                <TextMargin>0</TextMargin>
                              </FlexRow>
                            )}
                          </StyledIconContent>)}
                          {data?.is_allow_comments && (<StyledIconContent size='xs'>
                            <StyledSvgIcon
                              sx={{
                                path: {
                                  fill: 'rgb(233, 90, 8)',
                                },
                              }}
                            >
                              <MessageIcon />
                            </StyledSvgIcon>
                            {data?.comments_count || 0}
                          </StyledIconContent>)}
                        </FlexRow>
                      </div>
                      {data.cover_image_link && <StyledPostImages
                        onClick={() =>
                          navigate(structureViewPostUrl(data?.route_path, data?.institute_name))
                        }
                        style={{cursor: data.cover_image_link ? 'pointer' : ''}}
                      >
                        {data.cover_image_link && (
                          <img
                            src={data.cover_image_link}
                            alt='eventImage'
                            style={{
                              width: '116px',
                              height: '100px',
                              objectFit: 'cover',
                              borderRadius:4
                            }}
                          />
                        )}
                      </StyledPostImages>}
                    </StyledContentDivider>
                  )}
                </CardContent>
                {props.title != PostType.Sponsored.toString() &&
                index < props?.collection?.length - 1 && (
                  <StyledDivider
                    sx={{
                      marginTop: '0px !important',
                    }}
                  />
                )}
              </div>
            );
          })}
        </>
   
        {props.title == PostType.Sponsored.toString() && (
          <SwipeableTextMobileStepper collection={props.collection} />
        )}
      </StyledCard>
    </>
  );
}
