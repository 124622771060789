import { Paged } from 'models/Pagination';
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
  sendRequest,
} from './gateway';
import { Comment, CommentDto, PostListParams } from 'models/post';
import { UserProfileDto } from 'models/UserProfile';
import { capitalizeFirstLetter } from 'helper';
import { EntityResult } from 'models/ApiResult';

async function getComments(postId: string,params: PostListParams = {}) {
  if (!params) {
    params = {};
  }
  const fetchPromise = getRequest<Paged<CommentDto>>(`/v1/post/${postId}/comment`, {
    params,
    skipSpinner: true,
  });
  const commentList = await sendRequest(fetchPromise);
  const setOfUserIds: string[] = Array.from(
    new Set(commentList?.collection.map(user => user.user_id))
  );
  const listOfUserIds: string[] = [];
  setOfUserIds.forEach(userId => {
    listOfUserIds.push(userId);
  });

  const postListWithUserName: CommentDto[] = [];
  postListWithUserName.push(...commentList.collection);
  if (listOfUserIds?.length) {
    const fetchUserProfileDetail = postRequest<UserProfileDto[]>(
      '/association/api/user/profile/list',
      { user_ids: listOfUserIds },
      { skipSpinner: true }
    );
    const userProfileList = await sendRequest(fetchUserProfileDetail);

    postListWithUserName.forEach(comment => {
      const postUserId = parseInt(comment.user_id, 10);
      const matchingUserProfile = userProfileList.find(userProfile => {
        const userProfileUserId = parseInt(userProfile.user_id, 10);
        return userProfileUserId == postUserId;
      });
      if (matchingUserProfile) {
        comment.user_name = capitalizeFirstLetter(matchingUserProfile.name);
      }
    });
  }
  return postListWithUserName;
}

async function postComment(postId: string, comment: Comment) {
  const postPromise = postRequest<EntityResult<Comment>>(
    `/v1/post/${postId}/comment`,
    comment
  );
  return await sendRequest(postPromise);
}

async function deleteComment(postId: string, commentId: string) {
  const fetchDeleteUserPromise = deleteRequest<any>(
    `/v1/post/${postId}/comment/${commentId}`,{skipSpinner:true}
  );
  return await sendRequest(fetchDeleteUserPromise);
}

async function updateComment(
  postId: string,
  commentId: string,
  comment: Comment
) {
  const postPromise = putRequest<Comment>(
    `/v1/post/${postId}/comment/${commentId}`,
    comment
  );
  return await sendRequest(postPromise);
}

export { getComments, postComment, deleteComment, updateComment };
