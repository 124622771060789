import { styled, Tab, Tabs } from '@mui/material';
import { usePostViewStore } from 'store/post-view';
import { FlexRow } from '../styled/flex';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { listTags } from 'gateways/tag';

const StyledTags = styled(FlexRow)({
  maxWidth: 1220,
  justifyContent: 'center',
  margin: '0 auto',
  '& .MuiButtonBase-root': {
    fontWeight: 500,
    justifyContent: 'end',
    padding: '6px 16px',
    fontFamily: 'Poppins',
    textTransform:'capitalize',
    ':hover': {
      color: '#0f172a',
    },
  },
  '& .MuiTabs-indicator': {
    width: '100% ',
  },
});

type TagProps = {
  path?:string;
}

const TagsBar = (props:TagProps) => {
  const { isLoading } = usePostViewStore();
  const [selectedTab, setSelectedTab] = useState(0);
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const [filterList, setFilterList] = useState([
    { name: 'Home', id: 0, post_count: 0 },
    { name: 'Post', id: 1, post_count: 0 },
    { name: 'News', id: 2, post_count: 0 },
    { name: 'Event', id: 3, post_count: 0 },
  ]);

  async function getTagList() {
    const tagList = await listTags(undefined, 10);
    const topTags = tagList?.collection?.slice(0, 4) || [];
    const updatedFilterList = [
      ...filterList,
      { name: '|', id: 4, post_count: 0 },
      ...topTags,
    ];
    const routePath = updatedFilterList?.find(re=>re?.name == props?.path);
    setSelectedTab(routePath?.id || 0);
    setFilterList(updatedFilterList);
  }

  useEffect(() => {
    getTagList();
  }, []);

  useEffect(()=>{
    const path = filterList.find(re=>re?.name == props?.path);
    setSelectedTab(path?.id || 0);
  },[pathname]);

  function handleTag(newValue) {
    setSelectedTab(newValue.id);
    if (newValue.name == 'Home') {
      navigate('/');
    } else {
      navigate(`/home/${newValue.name}${(newValue.id && !['home','post','news','event','advertisement'].includes(newValue.name.toLowerCase())) ? `?tagId=${newValue.id}` : ''}`);
    }
  }

  return (
    <>
      <StyledTags size='xs'>
        {filterList?.length &&
          !isLoading &&
          filterList?.map((tag, i) => (
            <div key={i}>
              <Tabs
                TabIndicatorProps={{
                  style: { display: 'none' },
                }}
              >
                <Tab
                  label={tag?.name}
                  onClick={() => handleTag(tag)}
                  sx={{
                    '& .MuiTabs-indicator': {
                      width: '-webkit-fill-available',
                    },
                  }}
                  disableRipple
                  style={{
                    borderBottom:
                      selectedTab == tag.id
                        ? '4px solid #1a73e8'
                        : '4px solid white',
                    color: selectedTab == tag.id ? '#1a73e8' : '',
                    minWidth: tag?.name == '|' ? '0px' : '90px',
                    cursor: tag?.name == '|' ? 'text' : '',
                    opacity: tag?.name == '|' ? '0.7' : '1',
                  }}
                  disabled={tag?.name == '|' ? true : false}
                />
              </Tabs>
            </div>
          ))}
      </StyledTags>
    </>
  );
};

export default TagsBar;
