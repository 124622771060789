import { getUserClaims } from 'gateways/account';
import { User } from 'models/User';
import { setUser } from 'store/account';
import { addEvent, removeEvent } from 'store/spinner';

const LOGIN_URL = '/bff/login';

async function silentLogin() {
  const iframeSelector = '#bff-silent-login';
  const silentLoginUrl = '/bff/silent-login';
  const timeout = 5000;

  return new Promise((resolve) => {
    function onMessage(e: MessageEvent) {
      // look for messages sent from the BFF iframe
      if (e.data && e.data['source'] === 'bff-silent-login') {
        window.removeEventListener('message', onMessage);
        // send along the boolean result
        resolve(e.data.isLoggedIn);
      }
    }

    // listen for the iframe response to notify its parent (i.e. this window).
    window.addEventListener('message', onMessage);

    // we're setting up a time to handle scenarios when the iframe doesn't return immediaetly (despite prompt=none).
    // this likely means the iframe is showing the error page at IdentityServer (typically due to client misconfiguration).
    window.setTimeout(() => {
      window.removeEventListener('message', onMessage);

      // we can either just treat this like a "not logged in"
      resolve(false);
      // or we can trigger an error, so someone can look into the reason why
      // reject(new Error("timed_out"));
    }, timeout);

    // send the iframe to the silent login endpoint to kick off the workflow
    const iframe = document.querySelector(iframeSelector) as HTMLIFrameElement;
    if (iframe) {
      iframe.src = silentLoginUrl;
    }
  });
}

async function getUser() {
  try {
    const claims = await getUserClaims();
    const getClaim = (type: string) => claims.find(claim => claim.type === type)?.value;

    const user = {
      userId: getClaim('sub'),
      name: getClaim('name'),
      logoutUrl: getClaim('bff:logout_url'),
      authority: getClaim('bff:authority'),
      email: getClaim('email')
    } as User;
    // user.avatarText = getAvatarText(user.name);
    return user;
  } catch (error) {
    return null;
  }
}

async function login() {
  addEvent('User.Authentication');
  let user = await getUser();
  let isLoggedIn = false;

  if (user) {
    isLoggedIn = true;
  } else {
    if (await silentLogin()) {
      isLoggedIn = true;
      user = await getUser();
    }
  }

  if (user) {
    setUser(user);
  }

  if (!isLoggedIn) {
    window.location.replace(LOGIN_URL);
  } else {
    removeEvent('User.Authentication');
  }
}

export { login };
