import { useEffect, useMemo } from 'react';
import { SerializedEditorState } from 'lexical';
import { useNavigate, useParams } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { styled } from '@mui/material/styles';
import { PostStatus, PostType } from 'models/post';
import { usePostStore } from 'store/post';
import Editor from 'views/component/element/Editor';
import { structureViewPostUrl } from 'helper';

const Container = styled('div')({
  padding: 16,
  maxWidth: 968,
  margin: '0 auto',
  height: '100%',
  width: '100%',
  flex: '1 1',
});

type PostEditorProps = {
  type: PostType
}

const PostEditor = (props: PostEditorProps) => {
  const { postId } = useParams();
  const { setDirty, saveDraft, getPost, postContent, post } = usePostStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (postId) {
      getPost(postId);
    }
  }, [postId]);

  useEffect(() => {
    if(postId && post?.status === PostStatus.Published){
      navigate(structureViewPostUrl(post?.route_path, post?.institute_name));
    }
  },[post]);

  const handleSaveDraft = useMemo(() => debounce(async (value: string) => {
    const post = await saveDraft({ content: value, type: props.type });  
    if (!postId && post?.post_id) {
      navigate(`/${props.type}/${post.post_id}/edit`.toLowerCase(), { replace: true });
    }
  }, 5000), [post]);

  const handleChange = (value: string, editorState: SerializedEditorState) => {
    setDirty(editorState);
    handleSaveDraft(value);
  };

  const isFetchingData = Boolean(postId && !post);

  useEffect(() => {
    return () => {
      handleSaveDraft.cancel();
    };
  }, [handleSaveDraft]);

  return (
    <Container>
      {!isFetchingData && (
        <Editor
          placeholder='Tell your story...'
          onChange={handleChange}
          content={postContent}
        />
      )}
    </Container>
  );
};

export default PostEditor;
